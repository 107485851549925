import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from 'src/environments/environment';
import { AUTHGWCONSTANTS } from './authorization/authconstatnts';
import { BehaviorSubject } from 'rxjs';
import * as cloneDeep from 'lodash/cloneDeep';

@Injectable({
  providedIn: 'root',
})
export class AppService {
  public static dialogResult = false;
  onCartCountChange = new BehaviorSubject<number>(0);
  onOfflineCartReload = new BehaviorSubject<boolean>(true);
  onOnlineCartReload = new BehaviorSubject<void>(null);
  onWishListCountChange = new BehaviorSubject<number>(0);
  headers: any;
  public static globalCartCounter = 0;

  constructor(private http: HttpClient) {
    const token = localStorage.getItem(AUTHGWCONSTANTS.bearerToken);
    const xOriginKey = localStorage.getItem(AUTHGWCONSTANTS.xOriginKey);
    const xApiKey = localStorage.getItem(AUTHGWCONSTANTS.xApiKey);
    if (token !== undefined && token !== null && token !== '') {
      this.headers = cloneDeep({
        Authorization: 'Bearer ' + token,
        'x-Origin-Key': xOriginKey,
        'x-Api-Key': xApiKey,
      });
    } else {
      this.headers = cloneDeep(environment.guestHeaders);
    }
    this.headers = { ...this.headers, ...environment.platformInfo };
  }

  getProfile(): Observable<any> {
    return this.http.get<any>(environment.proxyApiUrl + '/web/api/resource/profile', {
      headers: this.headers,
    });
  }

  getCarts(urlParam: any): Observable<any> {
    return this.http.get<any>(environment.proxyApiUrl + '/web/api/online/customer_cart_v5', {
      headers: this.headers,
      params: urlParam,
    });
  }

  getWishlists(urlParam: any): Observable<any> {
    return this.http.get<any>(environment.proxyApiUrl + '/web/api/v4/component/user/wishlist', {
      headers: this.headers,
      params: urlParam,
    });
  }

  getEntity(): Observable<any> {
    return this.http.get<any>(environment.proxyApiUrl + '/web/api/element/entity', {
      headers: this.headers,
    });
  }

  postWishlist(body: any): Observable<any> {
    return this.http.post<any>(
      environment.proxyApiUrl + '/web/api/v4/component/user/wishlist',
      body,
      {
        headers: this.headers,
      },
    );
  }

  postProductInCart(body: any): Observable<any> {
    return this.http.post<any>(environment.proxyApiUrl + '/web/api/online/customer_cart_v5', body, {
      headers: this.headers,
    });
  }

  deleteWishlistItem(wishId: string): Observable<any> {
    return this.http.delete<any>(environment.proxyApiUrl + '/web/api/v4/component/user/wishlist', {
      headers: this.headers,
      params: { id: wishId },
    });
  }

  putServiceArea(areaId: string): Observable<any> {
    return this.http.put<any>(
      environment.proxyApiUrl + '/web/api/components/service/area',
      { id: areaId },
      {
        headers: this.headers,
      },
    );
  }

  getParentCategories(): Observable<any> {
    return this.http.get<any>(`${environment.proxyApiUrl}/web/api/resource/parent/category`, {
      headers: this.headers,
    });
  }

  getProducts(urlParams: any): Observable<any> {
    return this.http.get<any>(environment.proxyApiUrl + '/web/api/component/product/v4', {
      headers: this.headers,
      params: urlParams,
    });
  }
}
