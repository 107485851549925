import {
  Component,
  ElementRef,
  HostListener,
  OnInit,
  RendererFactory2,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import * as cloneDeep from 'lodash/cloneDeep';
import { CheckAuthToken } from 'src/app/handler/auth-check';
import { ProfileHelpers } from 'src/app/handler/profile_helpers';
import { AUTHGWCONSTANTS } from './authorization/authconstatnts';
import { AppService } from './app.service';
import { BuildConfig } from 'src/app/handler/build-config';
import { Util } from './util/util';
import { Log } from './util/log_util';
import { StaticMemory } from 'src/app/handler/static_memory';
import { MetaService } from './meta.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: [
    './app.component.scss',
  ],
  providers: [AppService],
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements OnInit {
  @ViewChild('top') topElement: ElementRef;
  categoryArray: any = [];
  subCategoryArray: any;
  shopObject: any = {};
  isBasicMenuVisible: boolean;
  isShopMenuVisible: boolean;

  scrollToTop(): void {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
  @HostListener('window:scroll', [])
  onWindowScroll(): void { }
  favIcon: HTMLLinkElement = document.querySelector('#appIcon');
  localStorageProduct: Array<any> = [];
  localStorageWishlist: Array<any> = [];
  productObj: any = {};
  wishlistObj: any = {};
  cartItemCount = 0;
  wishListItemCount = 0;
  loggedIn = false;
  loading = true;
  auth = new CheckAuthToken();
  corpUrl = environment.corpUrl;
  currentYear = new Date().getFullYear();
  buildVersion: String = environment.version + '+' + environment.versionCode;
  searchInput: string = '';
  profileName: string = '';
  showMyContainer: boolean = true;
  entityData: any;
  data: any = {
    logoLink: '',
  };
  primaryColor: string;
  secondaryColor: string;
  tertiaryColor: string;
  showHome: boolean;
  name: string = '';
  vHostUrl = environment.hostUrl;
  showCorpLogo = environment.showCorpLogo;

  selectedIndex: string = null;
  xcomDelivery: boolean;

  constructor(
    private router: Router,
    private service: AppService,
    private activatedroute: ActivatedRoute,
    private metaService: MetaService,
    private rendererFactory: RendererFactory2
  ) {
    this.service.onCartCountChange.subscribe((response) => {
      if (response > 0) {
        this.cartItemCount = response;
      } else {
        this.cartItemCount = 0;
      }
      AppService.globalCartCounter = response;
    });
    this.service.onWishListCountChange.subscribe((response) => {
      this.wishListItemCount = this.wishListItemCount + response;
    });
    this.service.onOfflineCartReload.subscribe((response) => {
      Log.i('OfflineReloadAppComponent', response);
      this.reloadOfflineCart();
    });
    this.service.onOnlineCartReload.subscribe((response) => {
      Log.i('onlineReloadAppComponent', response);
      this.getCarts();
    });
    if (environment.proxyApiUrl.includes('themeghalayanage')) {
      this.metaService.updateTitle('The Megahalayan Age - Official Website');
      this.metaService.updateDescription(
        'Explore the essence of Meghalaya: handlooms, handicrafts, local flavors, and more. Discover the heart of Meghalaya. Shop now!',
      );
      const renderer = rendererFactory.createRenderer(null, null);
      // Create a script element
      const script = renderer.createElement('script');
      // Set the script source (replace 'your-script-src' with the actual script source)
      renderer.setAttribute(script, 'src', `https://www.googletagmanager.com/gtag/js?id=${environment.google.analyticsKey}`);
      // Append the script element to the head of the document
      renderer.appendChild(document.head, script);

      // Create a script element
      const script2 = renderer.createElement('script');
      // Set the script source (replace 'your-script-src' with the actual script source)
      // Set the content of the script (replace 'your-script-content' with the actual script content)
      const scriptContent = document.createTextNode(
        `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', '${environment.google.analyticsKey}');
        `
      );
      renderer.appendChild(script2, scriptContent);
      // Append the script element to the head of the document
      renderer.appendChild(document.head, script2);

    } else if (environment.proxyApiUrl.includes('jompoi.com')) {
      this.metaService.updateTitle('Jompoi Store - Official Website');
      this.metaService.updateDescription(
        'Explore quality products at Jompoi Departmental Store, from everyday essentials to premium goods. Shop conveniently and confidently.',
      );
    }
  }

  ngOnInit(): void {
    // this.loadActivatedRoute();
    this.onServiceArea();
    this.onCheckAuth();
    this.scrollToTop();
    this.getEntity();
    this.getParentCategories();

    this.activatedroute.queryParams.subscribe((params) => {
      this.selectedIndex = params['pId'];
    });

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.updateMenuVisibility();
      }
    });

    setTimeout(() => {
      this.loading = false;
      this.loadScripts();
    }, 3000);
  }

  updateMenuVisibility(): void {
    const currentURL = this.router.url;
    if (environment.hostUrl.includes('themeghalayanage.store') ||
      environment.proxyApiUrl.includes('themeghalayanage.com')
    ) {
      this.isBasicMenuVisible = true;
      this.isShopMenuVisible = false;
    } else {
      this.isBasicMenuVisible = false;
      this.isShopMenuVisible = true;
    }
  }

  reloadOfflineCart(): void {
    let vCartCounter = 0;
    const value2 = JSON.parse(localStorage.getItem(BuildConfig.productArray));
    if (value2 !== null && value2 !== undefined) {
      for (let i = 0; i < value2.length; i++) {
        vCartCounter += value2[i].selectedQuantity;
      }
    }
    this.cartItemCount = vCartCounter;
  }

  getParentCategories(): void {
    this.service.getParentCategories().subscribe((success) => {
      if (success.status) {
        success.result.forEach((item) => {
          this.categoryArray.push(item);
        });
        this.subCategoryArray = success.result[0].subCategoriesInfo;

        this.activatedroute.queryParams.subscribe((paramsOrg) => {
          const catId = paramsOrg.catId;
          const selectedCategory = this.subCategoryArray.find((cat) => cat._id === catId);

          if (!selectedCategory) {
            this.shopObject.selectCategoryType = this.categoryArray[0]._id;
          }
        });
      }
    });
  }

  updateQueryParam(pCat, cCat): void {
    this.selectedIndex = pCat;
    let updatedUrl = Util.replaceURLParameter(this.router.url, 'pId', pCat);
    updatedUrl = Util.replaceURLParameter(updatedUrl, 'catId', cCat);
    Util.changeURLWithoutReloadWithRouter(this.router, updatedUrl);
    const queryParams = {
      pId: pCat,
      catId: cCat,
    };
    const urlSegment =
      this.router.url.includes('product') ||
      this.router.url.includes('cart') ||
      this.router.url.includes('checkout') ||
      this.router.url.includes('wishlist') ||
      this.router.url.includes('orders') ||
      this.router.url.includes('my-account');
    if (urlSegment) {
      this.router.navigate(['/shop'], { queryParams });
    }
  }

  onCheckAuth(): void {
    const authCheck = localStorage.getItem(AUTHGWCONSTANTS.bearerToken);
    if (authCheck && authCheck.length) {
      CheckAuthToken.loggedIn = true;
      this.loggedIn = true;
      this.getProfile();
      this.localStorageProduct = JSON.parse(localStorage.getItem(BuildConfig.productArray));
      this.localStorageWishlist = JSON.parse(localStorage.getItem(BuildConfig.wishlistArray));
      if (this.localStorageProduct && this.localStorageProduct.length) {
        for (let i = 0; i < this.localStorageProduct.length; i++) {
          this.productObj = cloneDeep({});
          this.productObj.subProductId = this.localStorageProduct[i].subproductId;
          this.productObj.selectedQuantity = this.localStorageProduct[i].selectedQuantity;
          this.productObj.isOrdered = false;
          this.postProductInCart();
        }
      } else {
        this.getCarts();
      }
      if (this.localStorageWishlist && this.localStorageWishlist.length) {
        for (let i = 0; i < this.localStorageWishlist.length; i++) {
          this.wishlistObj = cloneDeep({});
          this.wishlistObj.subProductId = this.localStorageWishlist[i].subProductId;
          this.wishlistObj.selectedQuantity =
            this.localStorageWishlist[i].subProduct.selectedQuantity;
          this.wishlistObj.productId = this.localStorageWishlist[i].productId;
          if (this.localStorageProduct && this.localStorageProduct.length) {
            for (let product of this.localStorageProduct) {
              if (product.subproductId === this.localStorageWishlist[i].subProductId) {
                this.wishlistObj.selectedQuantity = product.selectedQuantity;
              }
            }
          }
          this.postWishlist();
        }
      } else {
        this.getWishlists();
      }
    } else {
      CheckAuthToken.loggedIn = false;
      this.loggedIn = false;
      const cartCount = JSON.parse(localStorage.getItem(BuildConfig.cartCount));
      const wishCount = JSON.parse(localStorage.getItem(BuildConfig.wishCount));
      if (cartCount) {
        this.cartItemCount = cartCount;
      }
      if (wishCount) {
        this.wishListItemCount = wishCount;
      }
    }
  }

  loadActivatedRoute(): void {
    this.activatedroute.queryParams.subscribe((params) => {
      if (params.search && Object.keys(params).length) {
        this.searchInput = params.search;
      } else {
        this.searchInput = '';
      }
    });
  }

  onSearch(): void {
    this.router
      .navigate(['/shop'], { queryParams: { search: this.searchInput, pId: '', catId: '' } })
      .then(() => {
        this.scrollToTop();
      });
  }

  onSearchClear(): void {
    this.searchInput = '';
    Util.changeURLWithoutReloadWithRouter(
      this.router,
      Util.replaceURLParameter(this.router.url, 'search', ''),
    );
  }

  onCloseMenu(): void {
    document.getElementById('close-menu').click();
  }

  getEntity(): void {
    this.service.getEntity().subscribe((success) => {
      if (success.status) {
        if (success.result.length) {
          this.data = success.result[0];
          StaticMemory.entityData = this.data;
          this.primaryColor = success.result[0].primaryColor;
          this.secondaryColor = success.result[0].secondaryColor;
          this.tertiaryColor = success.result[0].tertiaryColor;
          StaticMemory.showHomeNavigation = success.result[0].showHome;
          StaticMemory.entityName = success.result[0].name;
          StaticMemory.cod = success.result[0].systemSettings.cod;
          StaticMemory.email = success.result[0].email;
          this.showHome = StaticMemory.showHomeNavigation;
          // this.titleService.setTitle(this.data.name);
          if (this.data.logo && this.data.logo.length) {
            this.data.logoLink = this.data.logo[0].linkMd;
          }
          if (this.data.footerLogo && this.data.footerLogo.length) {
            this.data.footerLogoLink = this.data.footerLogo[0].linkMd;
          }
          if (this.data.secondaryLogo && this.data.secondaryLogo.length) {
            this.data.secondaryLogoLink = this.data.secondaryLogo[0].linkMd;
          }

          if (this.data.fevicon && this.data.fevicon.length) {
            this.data.favIcon =
              environment.proxyApiUrl +
              '/uploads/' +
              this.data._id +
              '/entity_fevicon/' +
              this.data._id +
              '/' +
              this.data.fevicon[0].time +
              this.data.fevicon[0].mimeType;
          }
          this.favIcon.href = this.data.favIcon;
          StaticMemory.logo = success.result[0].logoLink;
          this.data.lat_text = 0;
          if (this.data.location) {
            const value = this.data.location.coordinates;
            this.data.lat_text = value[0];
          }
          this.data.long_text = 0;
          if (this.data.location) {
            const value = this.data.location.coordinates;
            this.data.long_text = value[1];
          }
        }
      }
    });
  }

  onMapInfo(lat: number, long: number): void {
    const googleMapsUrl = `https://www.google.com/maps/search/?api=1&query=${lat},${long}`;
    window.open(googleMapsUrl, '_blank');
  }

  getProfile(): void {
    this.service.getProfile().subscribe((success) => {
      if (success.status) {
        if (success.result.length > 0) {
          ProfileHelpers.profileDetails = success.result[0];
          localStorage.setItem(
            AUTHGWCONSTANTS.profile,
            JSON.stringify(ProfileHelpers.profileDetails),
          );
          this.profileName = ProfileHelpers.profileDetails.firstName;
        }
      }
    });
  }

  getCarts(): void {
    const urlParam = {
      serviceAreaId: environment.products.serviceAreaId,
      warehouseId: environment.products.warehouseId,
      channel: environment.defaultChannel,
    };
    let vCartCounter = 0;
    this.service.getCarts(urlParam).subscribe(
      (success) => {
        if (success.status) {
          if (success.result.length) {
            const result = success.result;
            for (const idx of result) {
              vCartCounter += idx.selectedQuantity;
            }
          }
        }
        this.cartItemCount = vCartCounter;
        AppService.globalCartCounter = this.cartItemCount;
      },
      (error) => {
        this.cartItemCount = vCartCounter;
        AppService.globalCartCounter = this.cartItemCount;
      },
    );
  }

  postProductInCart(): void {
    this.productObj.serviceAreaId = environment.products.serviceAreaId;
    this.productObj.warehouseId = environment.products.warehouseId;
    this.productObj.channel = environment.defaultChannel;
    this.service.postProductInCart(this.productObj).subscribe(
      (success) => {
        for (let i = 0; i < this.localStorageProduct.length; i++) {
          if (this.productObj.subProductId === this.localStorageProduct[i].subproductId) {
            this.localStorageProduct.splice(this.localStorageProduct[i], 1);
          }
        }
        if (!this.localStorageProduct || !this.localStorageProduct.length) {
          this.getCarts();
          localStorage.removeItem(BuildConfig.cartCount);
          localStorage.removeItem(BuildConfig.productArray);
        }
      },
      (error) => {
        for (let i = 0; i < this.localStorageProduct.length; i++) {
          if (this.productObj.subProductId === this.localStorageProduct[i].subproductId) {
            this.localStorageProduct.splice(this.localStorageProduct[i], 1);
          }
        }
        if (!this.localStorageProduct || !this.localStorageProduct.length) {
          this.getCarts();
          localStorage.removeItem(BuildConfig.cartCount);
          localStorage.removeItem(BuildConfig.productArray);
        }
      },
    );
  }

  getWishlists(): void {
    const urlParam = {
      warehouseId: environment.products.warehouseId,
    };

    this.service.getWishlists(urlParam).subscribe((success) => {
      if (success.status) {
        if (success.result.length) {
          this.wishListItemCount = success.result.length;
        }
      }
    });
  }

  postWishlist(): void {
    this.wishlistObj.serviceAreaId = environment.products.serviceAreaId;
    this.wishlistObj.warehouseId = environment.products.warehouseId;
    this.service.postWishlist(this.wishlistObj).subscribe(
      (success) => {
        for (let i = 0; i < this.localStorageWishlist.length; i++) {
          if (this.wishlistObj.subProductId === this.localStorageWishlist[i].subProductId) {
            this.localStorageWishlist.splice(this.localStorageWishlist[i], 1);
          }
        }
        if (!this.localStorageWishlist || !this.localStorageWishlist.length) {
          this.getWishlists();
          localStorage.removeItem(BuildConfig.wishCount);
          localStorage.removeItem(BuildConfig.wishlistArray);
        }
      },
      (error) => {
        for (let i = 0; i < this.localStorageWishlist.length; i++) {
          if (this.wishlistObj.subProductId === this.localStorageWishlist[i].subProductId) {
            this.localStorageWishlist.splice(this.localStorageWishlist[i], 1);
          }
        }
        if (!this.localStorageWishlist || !this.localStorageWishlist.length) {
          this.getWishlists();
          localStorage.removeItem(BuildConfig.wishCount);
          localStorage.removeItem(BuildConfig.wishlistArray);
        }
      },
    );
  }

  onServiceArea(): void {
    const areaId = environment.products.serviceAreaId;
    this.service.putServiceArea(areaId).subscribe((success) => {
    });
  }

  onSignin(): void {
    sessionStorage.removeItem('signBody');
    this.router.navigate(['/auth/signin']);
  }

  onSignout(): void {
    localStorage.clear();
    this.router.navigate(['/auth/signin']).then(() => {
      window.location.reload();
    });
    this.onCheckAuth();
  }

  loadScripts(): void {
    const dynamicScripts = [
      '../assets/js/main.js',
    ];
    for (let i = 0; i < dynamicScripts.length; i++) {
      const node = document.createElement('script');
      node.src = dynamicScripts[i];
      node.type = 'text/javascript';
      node.async = false;
      document.getElementsByTagName('head')[0].appendChild(node);
    }
  }
}
