import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Renderer2, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import { GlobalSnackBar } from 'src/app/other/open-snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { AppService } from '../../app.service';
import { ShopService } from './shop.service';
import { environment } from 'src/environments/environment';
import { BuildConfig } from 'src/app/handler/build-config';
import { cloneDeep } from 'lodash';
import { Util } from 'src/app/util/util';
import { CheckAuthToken } from 'src/app/handler/auth-check';
@Component({
  selector: 'app-shop',
  templateUrl: './shop.component.html',
  styleUrls: ['./shop.component.scss'],
  providers: [ShopService],
  encapsulation: ViewEncapsulation.None,
})
export class ShopComponent implements OnInit, AfterViewInit {
  data: Array<any> = [];
  shopObject: any = {};
  loading = false;
  catListArray: Array<any> = [];
  localStorageProduct: Array<any> = [];
  localStorageWishlist: Array<any> = [];
  chipsArray: Array<any> = [];
  productObj: any = {};
  wishlistObj: any = {};
  urlParam: any = {};
  errorMessage: string;
  catPriceArray = [
    { type: 1, name: 'Under ₹ 1000', value: '0-1000' },
    { type: 1, name: '₹ 1000 - ₹ 5000', value: '1000-5000' },
    { type: 1, name: '₹ 5000 - ₹ 10000', value: '5000-10000' },
    { type: 1, name: '₹ 10000 - ₹ 20000', value: '10000-20000' },
    { type: 1, name: 'Over ₹ 20000', value: '20000-100000' },
  ];
  pLimit = 12;
  pSkip = 0;
  selectedCategoryIndex: string = null;
  priceRange: string;
  categoryArray: Array<any> = [];
  pageSizeList: Array<number> = [12, 24, 48];
  selectedParentCategoryName: string = '';
  isDataLoaded: boolean = false;
  isMenuOpen: boolean = false;
  isCategoryMenuOpen: boolean = false;
  parentCategoryId = null;
  currentOpenCategory: any = null;
  subCategoryArray: Array<any> = [];
  @ViewChild('toggleButton') toggleButton!: ElementRef;
  @ViewChild('menu') menu!: ElementRef;
  @ViewChild('menuToggler') menuToggler!: ElementRef;
  @ViewChild('categoryMenu') categoryMenu!: ElementRef;

  constructor(
    private snackbar: GlobalSnackBar,
    private service: ShopService,
    private activatedroute: ActivatedRoute,
    private router: Router,
    private appService: AppService,
    private renderer: Renderer2,
  ) {
    this.localStorageProduct = JSON.parse(localStorage.getItem(BuildConfig.productArray));
    this.localStorageWishlist = JSON.parse(localStorage.getItem(BuildConfig.wishlistArray));
    this.shopObject = {};
    this.shopObject.selectCategoryType = environment.products.categoryId;
    this.shopObject.selectPriceObj = null;
    this.shopObject.selectBrand = null;
    this.shopObject.searchInput = '';
    this.shopObject.selectCatName = 'Category';
    this.shopObject.selectPrice = 'Price';

    // this.renderer.listen('window', 'click', (e: Event) => {
    //   if (e.target !== this.toggleButton.nativeElement && e.target !== this.menu.nativeElement) {
    //     this.isMenuOpen = false;
    //   }
    // });
  }

  ngOnInit(): void {
    sessionStorage.setItem(BuildConfig.xRoute, window.location.href);
    const req = [
      this.getParentCategories(),
      this.getCategories()
    ]

    Promise.all(req).then(() => {
      this.activatedroute.queryParams.subscribe((params) => {
        this.priceRange = params['priceRange'];
        this.selectedCategoryIndex = params['catId'];
        this.parentCategoryId = params['pId'];
      });
      if (this.isDataLoaded) {
        this.fetchSubCategoriesByParentId(this.parentCategoryId);
      }
    })
  }

  ngAfterViewInit(): void {
    this.renderer.listen('window', 'click', (e: Event) => {
      if (e.target !== this.toggleButton?.nativeElement && e.target !== this.menu?.nativeElement) {
        this.isMenuOpen = false;
      }
    });
  }

  toggleSubCategories(category: any): void {
    this.parentCategoryId = category._id;
    if (this.currentOpenCategory === category) {
      this.currentOpenCategory = null;
    } else {
      this.currentOpenCategory = category;
    }
  }

  isCategoryOpen(category: any): boolean {
    return this.currentOpenCategory === category;
  }

  toggleMenu() {
    this.isMenuOpen = !this.isMenuOpen;
  }

  onNavBarClick(subCategory: any): void {
    const parentCategory = this.categoryArray.find((category) =>
      category.subCategoriesInfo.some((subcat) => subcat._id === subCategory._id),
    );
    this.selectedCategoryIndex = subCategory._id;
    let updatedUrl = Util.replaceURLParameter(this.router.url, 'catId', subCategory._id);
    Util.changeURLWithoutReloadWithRouter(this.router, updatedUrl);
  }

  toggleCategoryMenu() {
    this.isCategoryMenuOpen = !this.isCategoryMenuOpen;
  }

  onSubMenuClick(pEvent: any, event: any) {
    this.subCategoryArray = pEvent.subCategoriesInfo;
    this.parentCategoryId = pEvent._id;
    this.selectedCategoryIndex = event._id;
    let updatedUrl = Util.replaceURLParameter(this.router.url, 'catId', event._id);
    updatedUrl = Util.replaceURLParameter(updatedUrl, 'pId', this.parentCategoryId);
    Util.changeURLWithoutReloadWithRouter(this.router, updatedUrl);
    this.isCategoryMenuOpen = false;
  }

  fetchSubCategoriesByParentId(parentId: string): void {
    const parentCategory = this.categoryArray.find((item) => item._id === parentId);
    this.subCategoryArray = parentCategory ? parentCategory.subCategoriesInfo : [];
  }

  getCategoryFromParentCategory(arr, pid) {
    const category = arr.find((item) => item._id === pid);
    this.selectedParentCategoryName = category?.categoryName;
  }

  getParentCategories(): void {
    this.loading = true;
    this.service.getParentCategories().subscribe((success) => {
      if (success.status) {
        this.categoryArray = success.result;
        this.isDataLoaded = true;
        if (this.parentCategoryId) {
          this.subCategoryArray = success.result.find(
            (item) => item._id === this.parentCategoryId,
          ).subCategoriesInfo;
        }
        this.activatedroute.queryParams.subscribe((paramsOrg) => {
          this.chipsArray = [];
          this.shopObject.searchInput = paramsOrg.search || '';

          const catId = paramsOrg.catId;
          const priceRange = paramsOrg.priceRange;

          const selectedCategory = this.categoryArray.find((cat) => cat._id === catId);
          if (selectedCategory) {
            this.chipsArray.push({
              type: 0,
              name: selectedCategory.categoryName,
              value: selectedCategory._id,
            });
            this.shopObject.selectCatName = selectedCategory.categoryName;
            this.shopObject.selectCategoryType = selectedCategory._id;

            this.subCategoryArray = selectedCategory.subCategoriesInfo;
          }

          const selectedPriceRange = this.catPriceArray.find((price) => price.value === priceRange);
          if (selectedPriceRange) {
            this.chipsArray.push({
              type: 1,
              name: selectedPriceRange.name,
              value: selectedPriceRange.value,
            });
            this.shopObject.selectPrice = selectedPriceRange.name;
          }

          if (!selectedCategory) {
            this.shopObject.selectCategoryType = this.categoryArray[0]._id;
          }

          //On click shop if child category Id is not present then it will take the timestamp
          if (!paramsOrg.catId && !paramsOrg.pId) {
            let childCatId = this.categoryArray.find(i => i._id === this.shopObject.selectCategoryType),
              paramChildCatId = childCatId.subCategoriesInfo[0]._id
            let updatedUrl = Util.replaceURLParameter(
              this.router.url,
              'catId',
              paramChildCatId ? paramChildCatId : encodeURIComponent(new Date().getTime().toString()),
            );
            updatedUrl = Util.replaceURLParameter(updatedUrl, 'pId', this.categoryArray[0]._id);
            Util.changeURLWithoutReloadWithRouter(this.router, updatedUrl);
          } else if (!paramsOrg.pId) {
            const updatedUrl = Util.replaceURLParameter(
              this.router.url,
              'pId',
              this.categoryArray[0]._id,
            );
            Util.changeURLWithoutReloadWithRouter(this.router, updatedUrl);
          }

          if (this.isDataLoaded) {
            this.fetchSubCategoriesByParentId(this.parentCategoryId);
          }
        });
      }
    });
  }

  private queryParamsLoaded = false;

  getCategories(): void {
    this.service.getCategories().subscribe((success) => {
      if (success.status) {
        this.catListArray = [{ type: 0, categoryName: 'All', _id: '0' }];
        for (let i = 0; i < success.result[0].length; i++) {
          success.result[0][i].type = cloneDeep(0);
          this.catListArray.push(success.result[0][i]);
        }

        this.activatedroute.queryParams.subscribe((paramsOrg) => {
          let params = {
            catId: paramsOrg.catId,
            search: paramsOrg.search,
            priceRange: paramsOrg.priceRange,
          };
          this.chipsArray = [];
          this.shopObject.searchInput = '';
          if (params.search && Object.keys(params).length) {
            this.shopObject.searchInput = params.search;
          }

          this.shopObject.selectCatName = 'Category';
          if (params.catId && Object.keys(params).length) {
            for (let i = 0; i < this.catListArray.length; i++) {
              if (params.catId === this.catListArray[i]._id) {
                if (!this.chipsArray.length) {
                  this.chipsArray.push({
                    type: 0,
                    name: this.catListArray[i].categoryName,
                    value: this.catListArray[i]._id,
                  });
                }
                this.shopObject.selectCatName = this.catListArray[i].categoryName;
                this.shopObject.selectCategoryType = params.catId;
                break;
              }
            }
          } else {
            this.chipsArray.push({
              type: 0,
              name: this.catListArray[0].categoryName,
              value: this.catListArray[0]._id,
            });
            this.shopObject.selectCatName = this.catListArray[0].categoryName;
            this.shopObject.selectCategoryType = this.catListArray[0]._id;
          }

          this.shopObject.selectPrice = 'Price';
          if (params.priceRange && Object.keys(params).length) {
            for (let i = 0; i < this.catPriceArray.length; i++) {
              if (params.priceRange === this.catPriceArray[i].value) {
                this.chipsArray.push({
                  type: 1,
                  name: this.catPriceArray[i].name,
                  value: this.catPriceArray[i].value,
                });
                this.shopObject.selectPrice = this.catPriceArray[i].name;
                break;
              }
            }
          }

          if (!this.chipsArray.length) {
            this.shopObject.selectCategoryType = environment.products.categoryId;
          }

          if (params.catId && Object.keys(params).length) {
            this.queryParamsLoaded = true;
            this.getProducts();
          }
        });
      }
    });
  }

  getProducts(): void {
    this.loading = true;
    this.urlParam = cloneDeep({});
    if (this.chipsArray && this.chipsArray.length) {
      let priceLower = null;
      let priceUpper = null;
      for (let i = 0; i < this.chipsArray.length; i++) {
        if (this.chipsArray[i].type === 0) {
          this.shopObject.selectCategoryType = this.chipsArray[i].value;
        }
        if (this.chipsArray[i].type === 1) {
          const splitPrice = this.chipsArray[i].value.split('-');
          priceLower = splitPrice[0];
          priceUpper = splitPrice[1];
        }
      }
      this.urlParam = {
        categoryId: this.selectedCategoryIndex,
        brandId: this.shopObject.selectBrand,
        serviceAreaId: environment.products.serviceAreaId,
        channels: JSON.stringify(['NECOM']),
        applyFilter: 1,
        limit: this.pLimit,
        skip: this.pSkip * this.pLimit,
        priceLower: priceLower,
        priceUpper: priceUpper,
        warehouseId: environment.products.warehouseId,
        searchWith: this.shopObject.searchInput,
      };
    } else {
      let priceLower = null;
      let priceUpper = null;
      this.urlParam = {
        categoryId: this.selectedCategoryIndex,
        brandId: this.shopObject.selectBrand,
        serviceAreaId: environment.products.serviceAreaId,
        channels: JSON.stringify(['NECOM']),
        applyFilter: 1,
        limit: this.pLimit,
        skip: this.pSkip * this.pLimit,
        priceLower: priceLower,
        priceUpper: priceUpper,
        warehouseId: environment.products.warehouseId,
        searchWith: this.shopObject.searchInput,
      };
    }
    this.service.getProducts(this.urlParam).subscribe(
      (success) => {
        if (success.status) {
          const value = cloneDeep(success.result);
          for (let i = 0; i < value.length; i++) {
            value[i].btnSpinner = false;
            if (value[i].subProducts && value[i].subProducts.length) {
              value[i].subProducts[0].default = true;
              value[i].defaultSubProduct = cloneDeep(value[i].subProducts[0]);
              value[i].defaultSubProduct.viewCartBtn = false;
              if (value[i].images && value[i].images.length) {
                if (value[i]?.images[0]?.linkMd && value[i]?.images[0]?.linkMd.length) {
                  value[i].imageLink = value[i]?.images[0]?.linkMd;
                } else {
                  value[i].imageLink =
                    environment.proxyApiUrl +
                    '/uploads/' +
                    value[i].entityId +
                    '/product_images/' +
                    value[i]._id +
                    '/' +
                    value[i].images[0].time +
                    value[i].images[0].mimeType;
                  value[i].images[0].link = value[i].imageLink;
                }
              } else {
                value[i].imageLink = environment.products.placeholder;
                value[i].images = [{ link: environment.products.placeholder }];
              }
              for (let k = 0; k < value[i].subProducts.length; k++) {
                value[i].subProducts[k].viewCartBtn = false;
              }
            } else {
              value.splice(i, 1);
            }
          }
          if (!CheckAuthToken.loggedIn) {
            if (this.localStorageProduct && this.localStorageProduct.length) {
              for (let i = 0; i < value.length; i++) {
                for (let x = 0; x < this.localStorageProduct.length; x++) {
                  if (value[i].defaultSubProduct._id === this.localStorageProduct[x].subproductId) {
                    value[i].defaultSubProduct.selectedQuantity =
                      this.localStorageProduct[x].selectedQuantity;
                  }
                  for (let k = 0; k < value[i].subProducts.length; k++) {
                    if (value[i].subProducts[k]._id === this.localStorageProduct[x].subproductId) {
                      value[i].subProducts[k].selectedQuantity =
                        this.localStorageProduct[x].selectedQuantity;
                    }
                  }
                }
              }
            }
            if (this.localStorageWishlist && this.localStorageWishlist.length) {
              for (let i = 0; i < value.length; i++) {
                for (let x = 0; x < this.localStorageWishlist.length; x++) {
                  if (
                    value[i].defaultSubProduct._id === this.localStorageWishlist[x].subProductId
                  ) {
                    value[i].defaultSubProduct.inWishList = true;
                  }
                  for (let k = 0; k < value[i].subProducts.length; k++) {
                    if (value[i].subProducts[k]._id === this.localStorageWishlist[x].subProductId) {
                      value[i].subProducts[k].inWishList = true;
                    }
                  }
                }
              }
            }
          }
          this.data = cloneDeep(value);
        } else {
          this.data = [];
          this.errorMessage = success.message.toUpperCase();
        }
        this.loading = false;
      },
      (error) => {
        this.loading = false;
        this.errorMessage = error.status + ' : HTTP ERROR IN GET PRODUCTS';
      },
    );
  }

  applyFilter(filterValue: string): void {
    if (!filterValue || !filterValue.length) {
      this.getProducts();
    }
  }

  onResetCategory(): void {
    if (this.chipsArray && this.chipsArray.length) {
      for (let idx of this.chipsArray) {
        if (idx.type === 0) {
          this.chipsArray.splice(this.chipsArray.indexOf(idx), 1);
          this.shopObject.selectCatName = 'Category';
          break;
        }
      }
    }
    Util.changeURLWithoutReloadWithRouter(
      this.router,
      Util.replaceURLParameter(this.router.url, 'catId', null),
    );
  }

  onResetPrice(): void {
    if (this.chipsArray.length) {
      for (let idx of this.chipsArray) {
        if (idx.type === 1) {
          this.chipsArray.splice(this.chipsArray.indexOf(idx), 1);
          this.shopObject.selectPrice = 'Price';
          break;
        }
      }
    }
    Util.changeURLWithoutReloadWithRouter(
      this.router,
      Util.replaceURLParameter(this.router.url, 'priceRange', null),
    );
  }

  onMenuItemClick(event: any): void {
    if (event.type === 0) {
      if (this.chipsArray && this.chipsArray.length) {
        for (let idx of this.chipsArray) {
          if (idx.type === 0) {
            this.chipsArray.splice(this.chipsArray.indexOf(idx), 1);
            break;
          }
        }
      }
      this.shopObject.selectCatName = event.categoryName;
      this.chipsArray.push({ type: event.type, name: event.categoryName, value: event._id });
      Util.changeURLWithoutReloadWithRouter(
        this.router,
        Util.replaceURLParameter(this.router.url, 'catId', event._id),
      );
    } else if (event.type === 1) {
      if (this.chipsArray && this.chipsArray.length) {
        for (let idx of this.chipsArray) {
          if (idx.type == 1) {
            this.chipsArray.splice(this.chipsArray.indexOf(idx), 1);
          }
        }
      }
      this.shopObject.selectPrice = event.name;
      this.chipsArray.push({ type: event.type, name: event.name, value: event.value });
      Util.changeURLWithoutReloadWithRouter(
        this.router,
        Util.replaceURLParameter(this.router.url, 'priceRange', event.value),
      );
    }
  }

  onMatChip(subProduct: any, product: any, productIndex: number): void {
    this.data[productIndex].defaultSubProduct = cloneDeep(subProduct);
    for (let i = 0; i < product.subProducts.length; i++) {
      if (product.subProducts[i].quantity === subProduct.quantity) {
        product.subProducts[i].default = true;
      }
      if (product.subProducts[i].quantity !== subProduct.quantity) {
        product.subProducts[i].default = false;
      }
    }
  }

  onAddWishList(defSubProduct: any, index: number, bolenValue: boolean, product: any): void {
    if (CheckAuthToken.loggedIn) {
      if (bolenValue === true) {
        this.wishlistObj = cloneDeep({});
        this.wishlistObj.subProductId = defSubProduct._id;
        this.wishlistObj.productId = product._id;
        this.wishlistObj.warehouseId = environment.products.warehouseId;
        this.wishlistObj.selectedQuantity = defSubProduct.selectedQuantity;
        this.onSubmitWishList(defSubProduct._id, index, 1, bolenValue);
      } else if (bolenValue === false) {
        this.wishlistObj = cloneDeep({});
        this.wishlistObj.subProductId = defSubProduct._id;
        this.wishlistObj.productId = product._id;
        this.wishlistObj.warehouseId = environment.products.warehouseId;
        this.wishlistObj.selectedQuantity = defSubProduct.selectedQuantity;
        this.onSubmitWishList(defSubProduct._id, index, -1, bolenValue);
      }
    } else {
      this.router.navigate(['auth/signin']);
      return;
      if (bolenValue === true) {
        let count = 1;
        this.localStorageWishlist = JSON.parse(localStorage.getItem(BuildConfig.wishlistArray));
        if (this.localStorageWishlist && this.localStorageWishlist.length > 0) {
          let wishCount = JSON.parse(localStorage.getItem(BuildConfig.wishCount));
          this.localStorageWishlist.push({
            images: product.images,
            productName: product.productName,
            btnSpinner: product.btnSpinner,
            subProduct: defSubProduct,
            productId: product._id,
            subProductId: defSubProduct._id,
            actualPrice: defSubProduct.actualPrice,
            availableQuantity: defSubProduct.availableQuantity,
            price: defSubProduct.price,
            quantity: defSubProduct.quantity,
            selectedQuantity: defSubProduct.selectedQuantity,
            currency: product.currency,
            link: product.imageLink,
            warehouseId: environment.products.warehouseId,
          });
          this.appService.onWishListCountChange.next(+count);
          if (wishCount !== null) {
            localStorage.setItem(BuildConfig.wishCount, JSON.stringify((wishCount += count)));
          }
          localStorage.setItem(
            BuildConfig.wishlistArray,
            JSON.stringify(this.localStorageWishlist),
          );
          this.data[index].defaultSubProduct.inWishList = true;
          const subP = this.data[index].subProducts;
          for (let i = 0; i < subP.length; i++) {
            if (defSubProduct._id === subP[i]._id) {
              this.data[index].subProducts[i].inWishList = true;
            }
          }
        } else {
          this.localStorageWishlist = [];
          this.localStorageWishlist.push({
            images: product.images,
            productName: product.productName,
            btnSpinner: product.btnSpinner,
            subProduct: defSubProduct,
            actualPrice: defSubProduct.actualPrice,
            availableQuantity: defSubProduct.availableQuantity,
            price: defSubProduct.price,
            quantity: defSubProduct.quantity,
            selectedQuantity: defSubProduct.selectedQuantity,
            productId: product._id,
            subProductId: defSubProduct._id,
            inWishList: bolenValue,
            currency: product.currency,
            link: product.imageLink,
            warehouseId: environment.products.warehouseId,
          });
          localStorage.setItem(
            BuildConfig.wishlistArray,
            JSON.stringify(this.localStorageWishlist),
          );
          this.appService.onWishListCountChange.next(count);
          localStorage.setItem(BuildConfig.wishCount, JSON.stringify(count));
          this.data[index].defaultSubProduct.inWishList = true;
          const subP = this.data[index].subProducts;
          for (let i = 0; i < subP.length; i++) {
            if (defSubProduct._id === subP[i]._id) {
              this.data[index].subProducts[i].inWishList = true;
            }
          }
        }
      }
      if (bolenValue === false) {
        let count = 1;
        this.localStorageWishlist = JSON.parse(localStorage.getItem(BuildConfig.wishlistArray));
        if (this.localStorageWishlist && this.localStorageWishlist.length > 0) {
          let wishCount = JSON.parse(localStorage.getItem(BuildConfig.wishCount));
          for (let idx of this.localStorageWishlist) {
            if (idx.subProductId == defSubProduct._id) {
              this.localStorageWishlist.splice(this.localStorageWishlist.indexOf(idx), 1);
              this.appService.onWishListCountChange.next(-count);
              break;
            }
          }
          localStorage.setItem(
            BuildConfig.wishlistArray,
            JSON.stringify(this.localStorageWishlist),
          );
          localStorage.setItem(BuildConfig.wishCount, JSON.stringify((wishCount -= count)));
          this.data[index].defaultSubProduct.inWishList = false;
          if (this.localStorageWishlist.length === 0) {
            localStorage.removeItem(BuildConfig.wishlistArray);
          }
          if (wishCount === 0) {
            localStorage.removeItem(BuildConfig.wishCount);
          }
          const subP = this.data[index].subProducts;
          for (let i = 0; i < subP.length; i++) {
            if (defSubProduct._id === subP[i]._id) {
              this.data[index].subProducts[i].inWishList = true;
            }
          }
        }
      }
    }
  }

  vCartCounter = 0;
  onAddCart(defSubProduct: any, index: number, product: any): void {
    if (CheckAuthToken.loggedIn) {
      this.productObj = cloneDeep({});
      this.productObj.selectedQuantity = 1;
      this.productObj.subProductId = defSubProduct._id;
      this.productObj.totalAmount = defSubProduct.price;
      this.onSubmitCart(defSubProduct._id, 1, index);
    } else {
      let count = 1;
      const value = JSON.parse(localStorage.getItem(BuildConfig.productArray));
      if (value !== null && value !== undefined) {
        let countValue = JSON.parse(localStorage.getItem(BuildConfig.cartCount));
        let foundProduct = false;
        for (let i = 0; i < value.length; i++) {
          if (defSubProduct._id === value[i].subproductId) {
            value[i].selectedQuantity += 1;
            value[i].totalAmount = value[i].selectedQuantity * defSubProduct.price;
            foundProduct = true;
            this.vCartCounter += value[i].selectedQuantity;
            if (countValue === null) {
              localStorage.setItem(BuildConfig.cartCount, JSON.stringify(count));
            } else {
              localStorage.setItem(BuildConfig.cartCount, JSON.stringify((countValue += count)));
            }
          }
        }
        if (foundProduct === false) {
          value.push({
            selectedQuantity: 1,
            productName: product.productName,
            subproductId: defSubProduct._id,
            price: defSubProduct.price,
            productId: product._id,
            totalAmount: defSubProduct.price,
            availableQuantity: defSubProduct.availableQuantity,
            quantity: defSubProduct.quantity,
            link: product.imageLink,
          });
          if (countValue !== null) {
            localStorage.setItem(BuildConfig.cartCount, JSON.stringify((countValue += count)));
          }
        }
        localStorage.setItem(BuildConfig.productArray, JSON.stringify(value));
        this.data[index].defaultSubProduct.viewCartBtn = true;
        this.data[index].defaultSubProduct.selectedQuantity = 1;
        const subP = this.data[index].subProducts;
        for (let i = 0; i < subP.length; i++) {
          if (defSubProduct._id === subP[i]._id) {
            this.data[index].subProducts[i].viewCartBtn = true;
            this.data[index].defaultSubProduct.selectedQuantity = 1;
          }
        }
      } else {
        const productArray = [];
        productArray.push({
          selectedQuantity: 1,
          productName: product.productName,
          subproductId: defSubProduct._id,
          productId: product._id,
          price: defSubProduct.price,
          totalAmount: defSubProduct.price,
          availableQuantity: defSubProduct.availableQuantity,
          quantity: defSubProduct.quantity,
          link: product.imageLink,
        });
        localStorage.setItem(BuildConfig.productArray, JSON.stringify(productArray));
        localStorage.setItem(BuildConfig.cartCount, JSON.stringify(count));
        this.data[index].defaultSubProduct.viewCartBtn = true;
        this.data[index].defaultSubProduct.selectedQuantity = 1;
        const subP = this.data[index].subProducts;
        for (let i = 0; i < subP.length; i++) {
          if (defSubProduct._id === subP[i]._id) {
            this.data[index].subProducts[i].viewCartBtn = true;
            this.data[index].defaultSubProduct.selectedQuantity = 1;
          }
        }
      }
      this.appService.onOfflineCartReload.next(true);
    }
  }

  reloadOfflineCart(): void {
    this.vCartCounter = 0;
    const value2 = JSON.parse(localStorage.getItem(BuildConfig.productArray));
    if (value2 !== null && value2 !== undefined) {
      for (let i = 0; i < value2.length; i++) {
        this.vCartCounter += value2[i].selectedQuantity;
      }
    }
    this.appService.onCartCountChange.next(this.vCartCounter);
  }

  onSubmitWishList(id: string, index: number, count: number, bolenValue: boolean): void {
    if (navigator.onLine) {
      if (bolenValue === true) {
        this.service.postWishlist(this.wishlistObj).subscribe(
          (success) => {
            if (success.status) {
              this.appService.onWishListCountChange.next(count);
              this.data[index].defaultSubProduct.inWishList = true;
              if (count > 0) {
                const subP = this.data[index].subProducts;
                for (let i = 0; i < subP.length; i++) {
                  if (id === subP[i]._id) {
                    this.data[index].subProducts[i].inWishList = true;
                  }
                }
              }
            } else {
              this.data[index].defaultSubProduct.inWishList = false;
            }
          },
          (error) => {
            this.data[index].defaultSubProduct.inWishList = false;
          },
        );
      }
      if (bolenValue === false) {
        this.service.deleteWishlistItem(this.wishlistObj).subscribe(
          (success) => {
            if (success.status) {
              this.appService.onWishListCountChange.next(count);
              this.data[index].defaultSubProduct.inWishList = false;
              if (count < 1) {
                const subP = this.data[index].subProducts;
                for (let i = 0; i < subP.length; i++) {
                  if (id === subP[i]._id) {
                    this.data[index].subProducts[i].inWishList = false;
                  }
                }
              }
            } else {
              this.data[index].defaultSubProduct.inWishList = true;
            }
          },
          (error) => {
            this.data[index].defaultSubProduct.inWishList = true;
          },
        );
      }
    } else {
      this.wishlistObj = cloneDeep({});
      this.snackbar.openDefaultSnackBar('No Internet');
    }
  }

  truncateLabel(label: string): string {
    if (label.length > 15) {
      return label.substring(0, 15) + '...';
    }
    return label;
  }

  setActiveBox(i: number, boxIndex: number) {
    this.data[i].defaultSubProduct = this.data[i].subProducts[boxIndex];
  }

  onSubmitCart(id: string, count: number, index: number): void {
    this.data[index].btnSpinner = true;
    this.productObj.warehouseId = environment.products.warehouseId;
    this.productObj.serviceAreaId = environment.products.serviceAreaId;
    this.productObj.channel = environment.defaultChannel;
    if (navigator.onLine) {
      this.service.postProductInCart(this.productObj).subscribe(
        (success) => {
          if (success.status) {
            this.appService.onCartCountChange.next(AppService.globalCartCounter + count);
            this.data[index].defaultSubProduct.selectedQuantity += cloneDeep(count);
            if (count > 0) {
              const subP = this.data[index].subProducts;
              for (let i = 0; i < subP.length; i++) {
                if (id === subP[i]._id) {
                  if (
                    this.data[index].defaultSubProduct.availableQuantity !==
                    this.data[index].defaultSubProduct.selectedQuantity
                  ) {
                    if (this.data[index].defaultSubProduct.selectedQuantity !== 5) {
                      this.data[index].subProducts[i].selectedQuantity++;
                    }
                  }
                }
              }
            } else {
              const subP = this.data[index].subProducts;
              for (let i = 0; i < subP.length; i++) {
                if (id === subP[i]._id) {
                  this.data[index].subProducts[i].selectedQuantity--;
                }
              }
            }
          }
          this.data[index].btnSpinner = false;
        },
        (error) => {
          this.data[index].btnSpinner = false;
        },
      );
    } else {
      this.data[index].btnSpinner = false;
      this.productObj = cloneDeep({});
      this.snackbar.openDefaultSnackBar('No Internet');
    }
  }

  setActiveCategory(categoryId: string): void {
    this.selectedCategoryIndex = categoryId;
  }

  clearSelectedFilter() {
    Util.changeURLWithoutReloadWithRouter(
      this.router,
      Util.replaceURLParameter(this.router.url, 'catId', ''),
    );
    Util.changeURLWithoutReloadWithRouter(
      this.router,
      Util.replaceURLParameter(this.router.url, 'priceRange', ''),
    );
  }

  onNextPage(): void {
    this.pSkip++;
    this.getProducts();
  }

  onPreviousPage(): void {
    this.pSkip--;
    this.getProducts();
  }

  onPageSizeChange(): void {
    this.getProducts();
  }
}
