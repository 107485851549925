import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { ShopComponent } from './pages/shop/shop.component';
import { environment } from 'src/environments/environment';

const hostUrl = environment.hostUrl;
const routes: Routes = [
  {
    path: '',
    redirectTo: '',
    component:
      hostUrl.includes('jompoi.com')
        ? ShopComponent
        : HomeComponent,
    pathMatch: 'full',
  },
  // { path: '', redirectTo: '', component: ShopComponent, pathMatch: 'full' },
  // { path: '', redirectTo: '', component: HomeComponent, pathMatch: 'full' },
  {
    path: 'auth',
    loadChildren: () =>
      import('./authorization/authorization.module').then((m) => m.AuthorizationModule),
  },
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then((m) => m.HomeModule),
  },
  {
    path: 'shop',
    loadChildren: () => import('./pages/shop/shop.module').then((m) => m.ShopModule),
  },
  {
    path: 'product/:id',
    loadChildren: () => import('./pages/product/product.module').then((m) => m.ProductModule),
  },
  {
    path: 'cart',
    loadChildren: () => import('./pages/cart/cart.module').then((m) => m.CartModule),
  },
  {
    path: 'checkout',
    loadChildren: () => import('./pages/checkout/checkout.module').then((m) => m.CheckoutModule),
  },
  {
    path: 'orders',
    loadChildren: () => import('./pages/orders/orders.module').then((m) => m.OrdersModule),
  },
  {
    path: 'wishlist',
    loadChildren: () => import('./pages/wishlist/wishlist.module').then((m) => m.WishlistModule),
  },
  {
    path: 'return',
    loadChildren: () => import('./pages/return/return.module').then((m) => m.ReturnModule),
  },
  {
    path: 'my-account',
    loadChildren: () =>
      import('./pages/my-account/my-account.module').then((m) => m.MyAccountModule),
  },
  {
    path: 'privacy-policy',
    loadChildren: () =>
      import('./pages/privacy-policy/privacy-policy.module').then((m) => m.PrivacyPolicyModule),
  },

  // TODO: Not yet complited.
  // {
  //   path: 'store',
  //   loadChildren: () =>
  //     import('./pages/store/store.module').then((m) => m.StoreModule),
  // },
  { path: '**', redirectTo: 'page-not-found-404', pathMatch: 'full' },
  {
    path: 'page-not-found-404',
    loadChildren: () => import('./error404/error404.module').then((r) => r.Error404Module),
  },
];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [],
})
export class AppRoutingModule { }
