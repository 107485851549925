import { Injectable } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root',
})
export class MetaService {
  constructor(
    private titleService: Title,
    private metaService: Meta,
  ) {}

  updateTitle(newTitle: string) {
    this.titleService.setTitle(newTitle);
  }

  updateDescription(newDescription: string) {
    const tag = {
      name: 'description',
      content: newDescription,
    };
    this.metaService.updateTag(tag);
  }
}
