// src/app/services/platform-info.service.ts

import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class PlatformInfo {

    detectBrowserName(): string {
        const userAgent = navigator.userAgent;
        if (userAgent.includes('Chrome')) {
            return 'Chrome';
        } else if (userAgent.includes('Firefox')) {
            return 'Firefox';
        } else if (userAgent.includes('Safari')) {
            return 'Safari';
        } else if (userAgent.includes('Edge')) {
            return 'Edge';
        } else if (userAgent.includes('Trident')) {
            return 'Internet Explorer';
        } else {
            return 'Unknown';
        }
    }

    detectOSName(): string {
        const userAgent = navigator.userAgent;
        if (userAgent.includes('Windows')) {
            return 'Windows';
        } else if (userAgent.includes('Macintosh')) {
            return 'macOS';
        } else if (userAgent.includes('Linux')) {
            return 'Linux';
        } else if (userAgent.includes('iPhone') || userAgent.includes('iPad')) {
            return 'iOS';
        } else if (userAgent.includes('Android')) {
            return 'Android';
        } else {
            return 'Unknown';
        }
    }

    detectPlatformVersion(): string {
        const userAgent = navigator.userAgent;

        // Detect Chrome version
        const chromeMatch = userAgent.match(/Chrome\/([\d.]+)/);
        if (chromeMatch) {
            return chromeMatch[1];
        }

        // Detect Firefox version
        const firefoxMatch = userAgent.match(/Firefox\/([\d.]+)/);
        if (firefoxMatch) {
            return firefoxMatch[1];
        }

        // Detect Edge version
        const edgeMatch = userAgent.match(/Edge\/([\d.]+)/);
        if (edgeMatch) {
            return edgeMatch[1];
        }

        // Detect Safari version
        const safariMatch = userAgent.match(/Version\/([\d.]+) Safari/);
        if (safariMatch) {
            return safariMatch[1];
        }

        // Detect Internet Explorer version
        const ieMatch = userAgent.match(/Trident\/([\d.]+)/);
        if (ieMatch) {
            // Internet Explorer versions are a bit more complex; return the appropriate version based on the match
            const tridentVersion = parseFloat(ieMatch[1]);
            if (tridentVersion >= 7.0) {
                return '11';
            } else if (tridentVersion >= 6.0) {
                return '10';
            } else {
                return '9 or below';
            }
        }

        // Add similar logic for other browsers or platforms here

        return 'Unknown';
    }

}
