import { PlatformInfo } from 'src/app/util/platfrom.info'

export const environment = {
  production: true,
  name: require('../../package.json').name,
  applicationId: require('../../package.json').id,
  version: require('../../package.json').version,
  applicationName: require('../../package.json').applicationName,
  versionCode: require('../../package.json').versionCode,
  proxyApiUrl: window.location.origin,
  hostUrl: window.location.origin,
  corpUrl: window.location.origin,
  xcomUrl: window.location.origin,
  showCorpLogo: false,
  platformInfo: {
    'X-Operating-System': new PlatformInfo().detectOSName(),
    'X-Platform': new PlatformInfo().detectBrowserName(),
    'X-Version': require('../../package.json').version.toString(),
    'X-Build-Number': require('../../package.json').versionCode.toString(),
    'X-Platform-Version': new PlatformInfo().detectPlatformVersion(),
  },
  products: {
    placeholder: '',
    serviceAreaId: '',
    categoryId: '',
    warehouseId: '',
  },
  defaultChannel: 'NECOM',
  xOrigin: {
    key: '',
  },
  newsAndArtOrigin: {
    key: 'gAAAAABjZQXSQRWxotWuu7h11SKXyuG7Zo3-hKSjehIgOGEBkS-z1hiaUxuFFG3YId93UEyLbGXcPVhhQkJCm4VFIrQ-UBaVl0fls4010WWqFagVM-A0NEc=',
  },

  guestHeaders: {
    Authorization: '',
    'x-Origin-Key': '',
    'x-Api-Key': '',
  },

  corpxOrigin: {
    key: 'gAAAAABkgcQgent0fvUp2RwJtvgnw7aVMd-TqfNPM1udFN6_jlsDh8Q-p8B66aE0eIeKX9yZWof0hDRRYSmmhyrOtIfkj-KR4jkCy5H5cwYNEmlnRRIJrm4=',
  },

  payment: {
    receiverName: 'MTIME Admin',
    approvalRefNo: 'MAGE-',
  },
  google: {
    apiKey: 'AIzaSyB3DoBggYszitIbhqyya6_-s_PTfVYti7o',
    analyticsKey: null
  },
};
