<app-loading *ngIf="loading"></app-loading>

<div class="main-div" *ngIf="!loading">
  <div class="nav-wrapper">
    <nav class="nav-scroller">
      <div class="nav-item"></div>
      <div class="nav-item" *ngFor="let item of subCategoryArray">
        <button
          class="cta-category"
          [ngClass]="{ active: item._id === selectedCategoryIndex }"
          (click)="setActiveCategory(item._id); onNavBarClick(item)">
          {{ item.categoryName }}
        </button>
      </div>
    </nav>
    <button class="filter-button" #toggleButton (click)="toggleMenu()">
      <i class="ri-filter-3-line" style="pointer-events: none"></i>
    </button>
  </div>

  <div
    class="side-menu-category"
    #categoryMenu
    [ngClass]="{ open: isCategoryMenuOpen, hidden: !isCategoryMenuOpen }">
    <nav class="navigation">
      <div class="navigation-block">
        <p class="navigation-title">Shop By Category</p>
        <div class="navigation-items-container" *ngFor="let category of categoryArray">
          <p class="navigation-item" (click)="toggleSubCategories(category)">
            <span>{{ category.categoryName.toLowerCase() }}</span>
            <i class="ri-arrow-right-s-line" [ngClass]="{ rotate: isCategoryOpen(category) }"></i>
          </p>
          <div class="sub-categories-holder" [hidden]="!isCategoryOpen(category)">
            <p
              (click)="onSubMenuClick(category, subcats)"
              class="sub-category-item"
              [ngClass]="{ active: subcats._id === selectedCategoryIndex }"
              *ngFor="let subcats of category.subCategoriesInfo">
              {{ subcats.categoryName }}
            </p>
          </div>
        </div>
      </div>
    </nav>
  </div>

  <div class="chip" *ngIf="priceRange">
    <span class="text">{{ priceRange }}</span>
    <i class="ri-close-line" (click)="clearSelectedFilter()"></i>
  </div>

  <aside class="filter-container" #menu [ngClass]="{ open: isMenuOpen, hidden: !isMenuOpen }">
    <div class="container">
      <div class="filter-item">
        <h3 class="filter-title">Price</h3>
        <div class="filter-contents">
          <button
            class="filter-button"
            *ngFor="let item of catPriceArray"
            [class.highlighted]="priceRange === item.value.toString()"
            (click)="onMenuItemClick(item); toggleMenu()">
            {{ item.name }}
          </button>
        </div>
      </div>
    </div>
  </aside>

  <!-- category scrolling ends here -->
  <div class="container-fluid">
    <app-loading *ngIf="loading"></app-loading>
    <div *ngIf="!loading && data.length">
      <div class="row">
        <div
          *ngFor="let idx of data; let i = index"
          class="col-lg-3 col-md-4 col-sm-6"
          style="padding-bottom: 30px">
          <div class="banner-wrap">
            <div class="overlay-continer" style="position: relative">
              <div class="overlay-btn-continer">
                <div class="banner-img banner-img-overflow">
                  <a [routerLink]="['/product/', idx?._id]">
                    <img
                      class="main-product-image"
                      loading="lazy"
                      [src]="idx?.images[0]?.linkMd ? idx?.images[0]?.linkMd : idx?.images[0]?.link"
                      alt="" />
                  </a>
                </div>
                <div class="overlay-div">
                  <div
                    class="overlay-icon"
                    fxLayout="column"
                    style="position: absolute; top: 10px; right: 10px; cursor: pointer">
                    <div>
                      <mat-icon
                        (click)="onAddWishList(idx.defaultSubProduct, i, true, idx)"
                        style="color: #000"
                        *ngIf="idx?.defaultSubProduct && !idx?.defaultSubProduct?.inWishList"
                        title="Add to Wishlist">
                        favorite_border
                      </mat-icon>
                      <mat-icon
                        (click)="onAddWishList(idx.defaultSubProduct, i, false, idx)"
                        style="color: red"
                        *ngIf="idx?.defaultSubProduct && idx?.defaultSubProduct?.inWishList"
                        title="Remove to Wishlist">
                        favorite
                      </mat-icon>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            style="width: 100%; left: 0; background: none; margin-top: 10px"
            class="banner-content-15">
            <a [routerLink]="['/product/', idx?._id]" style="font-size: 16px">
              <div class="product-label">
                {{ idx.productName | slice : 0 : 60 }}
                {{ idx.productName.length > 60 ? '.....' : '' }}
              </div>
            </a>
            <div fxLayout="row" fxLayoutAlign="space-between center">
              <p
                [routerLink]="['/product/', idx?._id]"
                style="
                  font-size: 16px;
                  cursor: pointer;
                  font-weight: 300;
                  font-family: sans-serif;
                  margin: 10px 0px;
                ">
                <span style="color: #1f1f1f; font-size: 17px; font-weight: 600">
                  {{ idx.currency }} {{ idx?.defaultSubProduct?.price }}
                </span>
                <del
                  *ngIf="idx?.defaultSubProduct?.price !== idx?.defaultSubProduct?.actualPrice"
                  style="color: #abadae; font-size: 13px">
                  {{ idx?.defaultSubProduct?.actualPrice }}
                </del>
              </p>
            </div>
          </div>
          <!-- <div *ngIf="idx?.defaultSubProduct?.availableQuantity > 0">
            <div *ngIf="!idx?.btnSpinner">
              <div
                fxLayout="row"
                fxLayoutAlign="start center"
                *ngIf="idx?.defaultSubProduct?.selectedQuantity < 1"
                class="add-to-cart-btn mb-3"
                color="primary"
                (click)="onAddCart(idx?.defaultSubProduct, i, idx)">
                <i class="icofont-cart" style="font-size: 20px; margin-right: 10px"></i>
                Add To Cart
              </div>
            </div>
            <div
              fxLayout="row"
              fxLayoutAlign="start center"
              *ngIf="idx?.defaultSubProduct?.selectedQuantity > 0"
              class="view-cart-button"
              color="primary"
              [routerLink]="['/cart']">
              <i class="icofont-cart" style="font-size: 20px; margin-right: 10px"></i>
              View Cart
            </div>
            <div
              style="width: 100%; border-radius: 0; height: 40px; background: transparent"
              class="mat-spinner-black"
              *ngIf="idx.btnSpinner"
              fxLayoutAlign="space-around center">
              <mat-spinner [diameter]="38"></mat-spinner>
            </div>
          </div> -->
          <div fxLayout="row wrap">
            <div
              *ngFor="let box of idx?.subProducts; let boxIndex = index"
              [ngClass]="{ box: true, inactive: idx?.defaultSubProduct?._id !== box._id }"
              (click)="setActiveBox(i, boxIndex)"
              fxFlex="30%"
              class="box">
              <span [class.active-text]="idx?.defaultSubProduct?._id === box._id" class="label">{{
                truncateLabel(box.quantity)
              }}</span>
            </div>
          </div>
          <div fxLayout="row" fxLayoutAlign="start start">
            <div
              fxLayout="row"
              fxLayoutAlign="start center"
              *ngIf="idx?.defaultSubProduct?.availableQuantity < 1"
              class="out-of-stock-label"
              color="primary">
              Out Of Stock
            </div>
            <div *ngIf="idx?.defaultSubProduct?.availableQuantity > 0">
              <div *ngIf="!idx?.btnSpinner">
                <div
                  fxLayout="row"
                  fxLayoutAlign="start center"
                  *ngIf="idx?.defaultSubProduct?.selectedQuantity < 1"
                  class="add-to-cart-btn"
                  color="primary"
                  (click)="onAddCart(idx?.defaultSubProduct, i, idx)">
                  <i class="icofont-cart" style="font-size: 20px; margin-right: 10px"></i>
                  Add To Cart
                </div>
              </div>
              <div
                fxLayout="row"
                fxLayoutAlign="start center"
                *ngIf="idx?.defaultSubProduct?.selectedQuantity > 0"
                class="view-cart-button"
                color="primary"
                [routerLink]="['/cart']">
                <i class="icofont-cart" style="font-size: 20px; margin-right: 10px"></i>
                View Cart
              </div>
              <div
                style="width: 150px; height: 40px; background: transparent"
                class="mat-spinner-black"
                *ngIf="idx.btnSpinner"
                fxLayoutAlign="space-around center">
                <mat-spinner [diameter]="38"></mat-spinner>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- pagination block -->

  <div *ngIf="data.length || pSkip" class="pagiFormfield" fxLayout="column">
    <div fxLayout="row wrap" fxLayoutAlign="end center">
      <p class="p-text">Items per page:</p>
      <mat-form-field style="width: 70px" appearance="outline">
        <mat-select [(ngModel)]="pLimit" (selectionChange)="onPageSizeChange()">
          <mat-option *ngFor="let idx of pageSizeList" [value]="idx">
            {{ idx }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <div fxLayout="row" fxLayoutAlign="end center">
        <p class="p-text">Page {{ pSkip + 1 }}</p>
      </div>
      <div fxLayout="row" fxLayoutAlign="center center">
        <button
          [disabled]="pSkip === 0"
          mat-icon-button
          (click)="onPreviousPage()"
          matTooltip="Previous Page">
          <mat-icon>chevron_left</mat-icon>
        </button>
        <button
          [disabled]="data.length < pLimit"
          mat-icon-button
          (click)="onNextPage()"
          matTooltip="Next Page">
          <mat-icon>chevron_right</mat-icon>
        </button>
      </div>
    </div>
  </div>

  <!-- end of pagination block -->

  <div
    style="text-align: center; font-size: 20px; margin: auto; padding-top: 200px"
    *ngIf="!data.length">
    {{ errorMessage }}
  </div>
</div>
