<app-loading *ngIf="loading"></app-loading>
<div *ngIf="!loading" class="main-wrapper main-wrapper-3">
  <div class="header-area section-padding-1 transparent-bar">
    <div class="header-large-device">
      <div
        style="border-bottom: 1px solid rgba(0, 0, 0, 0.1)"
        class="header-bottom sticky-bar"
        fxLayoutAlign="space-between center">
        <div class="header-fit" fxLayout="column " fxLayoutAlign="space-between center">
          <div class="header-fit" fxLayout="row" fxLayoutAlign="space-between center">
            <div fxLayout="row" fxLayoutAlign="start center" class="logo">
              <a [href]="vHostUrl">
                <img class="app-bar-logo" [src]="data.logoLink" alt="primary-logo" />
              </a>
            </div>
            `
            <div style="width: 50%" fxLayout="row">
              <div class="header-search-bar" fxLayout="row" fxLayoutAlign="space-evenly center">
                <input
                  style="border-radius: 2rem"
                  [(ngModel)]="searchInput"
                  (keyup.enter)="onSearch()"
                  matInput
                  type="text"
                  placeholder="Search for products" />
                <button
                  class="close-btn"
                  *ngIf="searchInput.length"
                  (click)="onSearchClear()"
                  mat-icon
                  button
                  matSuffix>
                  <mat-icon>close</mat-icon>
                </button>
                <button
                  style="border-radius: 0 2rem 2rem 0; transform: translateX(-2rem)"
                  id="product-search"
                  aria-labelledby="product-search-label"
                  class="search-btn"
                  (click)="onSearch()">
                  <mat-icon aria-hidden="true">search</mat-icon>
                  <span id="product-search-label" class="sr-only">Search</span>
                </button>
              </div>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center">
              <a
                *ngIf="data.secondaryLogoLink && showCorpLogo"
                target="_blank"
                [matMenuTriggerFor]="orgMenu"
                #trigger="matMenuTrigger"
                (click)="trigger.openMenu()">
                <img class="app-bar-logo" [src]="data.secondaryLogoLink" alt="secondary-logo" />
              </a>
              <div *ngIf="!data.secondaryLogoLink" class="app-bar-logo"></div>
              <mat-menu
                #orgMenu="matMenu"
                (mouseenter)="orgMenu.open()"
                (mouseleave)="orgMenu.close()">
                <a mat-menu-item [href]="corpUrl + '/home'" class="menu-item">Home</a>
                <a mat-menu-item [href]="corpUrl + '/tenders'" class="menu-item">Tenders</a>
                <a mat-menu-item [href]="corpUrl + '/projects'" class="menu-item">Projects</a>
                <a mat-menu-item [href]="corpUrl + '/events'" class="menu-item">Events</a>
                <a mat-menu-item [matMenuTriggerFor]="subMenu" class="menu-item">Careers</a>
                <mat-menu
                  #subMenu="matMenu"
                  (mouseenter)="subMenu.open()"
                  (mouseleave)="subMenu.close()">
                  <a mat-menu-item [href]="corpUrl + '/recruitments'" class="menu-item"
                    >Recruitments</a
                  >
                  <a mat-menu-item [href]="corpUrl + '/programs'" class="menu-item">Programs</a>
                </mat-menu>
                <a mat-menu-item [href]="corpUrl + '/press-release'" class="menu-item"
                  >Press Release</a
                >
                <a mat-menu-item [href]="corpUrl + '/eaps'" class="menu-item">EAPs</a>
                <a mat-menu-item [href]="corpUrl + '/about-us'" class="menu-item">About Us</a>
              </mat-menu>
            </div>
          </div>
          <div class="header-v2" fxLayout="row" fxLayoutAlign="space-between center">
            <div class="col-xl-7 col-md-7">
              <nav class="navbar navbar-expand nav-dark nav-primary-hover nav-line-active">
                <button
                  class="navbar-toggler"
                  type="button"
                  data-toggle="collapse"
                  data-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent"
                  aria-expanded="false"
                  aria-label="Toggle navigation">
                  <i class="flaticon-menu-2 flat-small text-primary"></i>
                </button>
                <div class="collapse navbar-collapse" id="navbarSupportedContent">
                  <ul class="navbar-nav" *ngIf="isShopMenuVisible">
                    <li
                      (click)="onSearchClear()"
                      class="nav-item"
                      *ngFor="let item of categoryArray">
                      <a
                        class="nav-link"
                        [ngClass]="{ active: item._id === selectedIndex }"
                        (click)="updateQueryParam(item._id, item.subCategoriesInfo[0]._id)"
                        >{{ item.categoryName.toLowerCase() }}</a
                      >
                    </li>
                  </ul>
                  <ul class="navbar-nav" *ngIf="isBasicMenuVisible">
                    <li class="nav-item">
                      <a *ngIf="showHome" class="nav-link" [routerLink]="['/']">Home</a>
                    </li>
                    <li class="nav-item">
                      <a (click)="scrollToTop()" class="nav-link" [routerLink]="['/shop']">Shop</a>
                    </li>
                    <li class="nav-item">
                      <a id="collectionAnchor" class="nav-link" href="#collection">Collection</a>
                    </li>
                    <li class="nav-item">
                      <a id="brandAnchor" class="nav-link" href="#brand">Brand</a>
                    </li>
                    <li class="nav-item">
                      <a id="eventsAnchor" class="nav-link" href="#events">Events</a>
                    </li>
                    <li class="nav-item">
                      <a id="articlesAnchor" class="nav-link" href="#articles">Articles</a>
                    </li>
                  </ul>
                </div>
              </nav>
            </div>
            <div
              class="col-xl-5 col-md-5"
              fxLayoutGap="30px"
              fxLayout="row"
              fxLayoutAlign="end center">
              <a
                *ngIf="loggedIn"
                (click)="scrollToTop()"
                class="toolbar-text"
                [routerLink]="['/wishlist']">
                <span
                  matBadgeSize="small"
                  matBadgeColor="warn"
                  [matBadge]="wishListItemCount > 9 ? '9+' : wishListItemCount"
                  [matBadgeHidden]="wishListItemCount === 0 || wishListItemCount < 1"
                  matBadgeOverlap="false"
                  matBadgePosition="before">
                </span>
                <i class="icofont-ui-love mr-5"></i>
                <span>Wishlist</span>
              </a>
              <a class="toolbar-text" href="/cart">
                <span
                  matBadgeSize="small"
                  matBadgeColor="warn"
                  [matBadge]="cartItemCount"
                  [matBadgeHidden]="cartItemCount === 0 || cartItemCount < 1"
                  matBadgeOverlap="false"
                  matBadgePosition="before"></span>
                <i class="icofont-cart mr-5"></i>
                <span>Cart</span>
              </a>
              <div
                id="xcom_home_page_sign_in_button"
                class="toolbar-text"
                *ngIf="!loggedIn"
                (click)="onSignin()">
                <i class="icofont-login mr-5"></i>
                Sign In
              </div>
              <div
                style="cursor: pointer"
                class="toolbar-text"
                *ngIf="loggedIn"
                [matMenuTriggerFor]="accountMenu">
                <i class="icofont-ui-user mr-5"></i>
                {{ profileName }}
              </div>
              <mat-menu #accountMenu="matMenu">
                <button
                  mat-menu-item
                  fxLayout="row"
                  fxLayoutAlign="start center"
                  [routerLink]="['/orders']">
                  <i class="icofont-cart mr-10 nav-menu-icon"></i>
                  <span class="nav-menu-text">Orders</span>
                </button>
                <button
                  mat-menu-item
                  fxLayout="row"
                  fxLayoutAlign="start center"
                  [routerLink]="['/my-account']">
                  <i class="icofont-user mr-10 nav-menu-icon"></i>
                  <span class="nav-menu-text">My Account</span>
                </button>
                <button
                  id="xcom_home_page_sign_out_button"
                  mat-menu-item
                  fxLayout="row"
                  fxLayoutAlign="start center"
                  (click)="onSignout()">
                  <i class="icofont-logout mr-10 nav-menu-icon"></i>
                  <span class="nav-menu-text">Sign out</span>
                </button>
              </mat-menu>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Mobile view -->
    <div
      style="box-shadow: 0 2px 3px -1px rgba(0, 0, 0, 0.1)"
      class="header-small-device header-small sticky-bar">
      <div class="header-fit" fxLayout="column" fxLayoutAlign="space-between center">
        <div
          style="width: 100%; padding-bottom: 10px"
          fxLayout="row"
          fxLayoutAlign="space-between center">
          <a [routerLink]="['/']">
            <img [src]="data.logoLink" class="mobile-app-bar-logo" alt="mobile-logo" />
          </a>
          <div fxLayoutGap="10px" fxLayout="row">
            <a *ngIf="loggedIn" class="toolbar-text" [routerLink]="['/wishlist']">
              <span
                matBadgeSize="small"
                matBadgeColor="warn"
                [matBadge]="wishListItemCount"
                [matBadgeHidden]="wishListItemCount === 0 || wishListItemCount < 1"
                matBadgeOverlap="false"
                matBadgePosition="before"></span>
              <i class="icofont-ui-love mr-5"></i>
            </a>
            <a class="toolbar-text" href="/cart">
              <span
                matBadgeSize="small"
                matBadgeColor="warn"
                [matBadge]="cartItemCount"
                [matBadgeHidden]="cartItemCount === 0 || cartItemCount < 1"
                matBadgeOverlap="false"
                matBadgePosition="before"></span>
              <i class="icofont-cart mr-5"></i>
            </a>
            <div class="toolbar-text" *ngIf="!loggedIn" (click)="onSignin()">
              <i class="icofont-login mr-5"></i>
            </div>
            <a class="toolbar-text" *ngIf="loggedIn" [matMenuTriggerFor]="accountMenu">
              <i class="icofont-ui-user mr-5"></i>
            </a>
            <mat-menu #accountMenu="matMenu">
              <button
                mat-menu-item
                fxLayout="row"
                fxLayoutAlign="start center"
                [routerLink]="['/orders']">
                <i class="icofont-cart mr-10 nav-menu-icon"></i>
                <span class="nav-menu-text">Orders</span>
              </button>
              <button
                mat-menu-item
                fxLayout="row"
                fxLayoutAlign="start center"
                (click)="onSignout()">
                <i class="icofont-logout mr-10 nav-menu-icon"></i>
                <span class="nav-menu-text">Sign out</span>
              </button>
            </mat-menu>
          </div>
        </div>
        <div style="width: 100%" fxLayout="row" fxLayoutAlign="space-between center">
          <div class="header-search-bar" fxLayout="row" fxLayoutAlign="space-evenly center">
            <input
              style="border-radius: 2rem"
              (keyup.enter)="onSearch()"
              [(ngModel)]="searchInput"
              matInput
              type="text"
              placeholder="Search" />
            <button
              class="close-btn"
              *ngIf="searchInput.length"
              (click)="onSearchClear()"
              mat-icon
              button
              matSuffix>
              <mat-icon>close</mat-icon>
            </button>
            <button
              style="border-radius: 0 2rem 2rem 0; transform: translateX(-2rem)"
              (click)="onSearch()"
              mat-button
              class="search-btn">
              <mat-icon>search</mat-icon>
            </button>
          </div>
          <div style="width: 10%" class="header-action-wrap header-action-flex header-action-mrg-1">
            <div style="margin-left: 10px" class="same-style header-info">
              <button class="mobile-menu-button-active">
                <i class="ri-menu-2-line" style="color: #333; font-size: 1.5rem"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="mobile-menu-active clickalbe-sidebar-wrapper-style-1">
    <div class="clickalbe-sidebar-wrap">
      <div class="mobile-menu-bar" fxLayout="row" fxLayoutAlign="end center">
        <div fxLayoutAlign="end center" style="width: 20%">
          <a id="close-menu" class="sidebar-close">
            <i style="color: #fff; font-size: 24px" class="icofont-close-line"></i>
          </a>
        </div>
      </div>
      <div class="mobile-menu-content-area sidebar-content-100-percent">
        <div class="menu-and-category">
          <div class="tab-content" id="menu-and-categoryContent">
            <ul
              *ngIf="isBasicMenuVisible"
              class="nav nav-pills wc-tabs"
              id="menu-and-category"
              role="tablist">
              <li [fxFlex]="showCorpLogo ? '50' : '100'" class="nav-item" role="presentation">
                <a
                  class="nav-link active"
                  id="pills-push-menu-tab"
                  data-bs-toggle="pill"
                  href="#pills-push-menu"
                  role="tab"
                  aria-controls="pills-push-menu"
                  aria-selected="true">
                  Menu
                </a>
              </li>
              <li fxFlex="50" *ngIf="showCorpLogo" class="nav-item" role="presentation">
                <a
                  class="nav-link"
                  id="pills-push-categories-tab"
                  data-bs-toggle="pill"
                  href="#pills-push-categories"
                  role="tab"
                  aria-controls="pills-push-categories"
                  aria-selected="true">
                  Organization
                </a>
              </li>
            </ul>

            <div
              class="tab-pane fade show active woocommerce-Tabs-panel woocommerce-Tabs-panel--description"
              id="pills-push-menu">
              <div class="push-navbar">
                <ul class="navbar-nav" *ngIf="isShopMenuVisible">
                  <li (click)="onCloseMenu()" class="nav-item" *ngFor="let item of categoryArray">
                    <a
                      class="nav-link"
                      [ngClass]="{ active: item._id === selectedIndex }"
                      (click)="updateQueryParam(item._id, item.subCategoriesInfo[0]._id)"
                      >{{ item.categoryName.toLowerCase() }}</a
                    >
                  </li>
                </ul>
                <ul class="navbar-nav" *ngIf="isBasicMenuVisible">
                  <li (click)="onCloseMenu()" class="nav-item">
                    <a *ngIf="showHome" class="nav-link" [routerLink]="['/']">Home</a>
                  </li>
                  <li (click)="onCloseMenu()" class="nav-item">
                    <a class="nav-link" [routerLink]="['/shop']">Shop</a>
                  </li>
                  <li (click)="onCloseMenu()" class="nav-item">
                    <a class="nav-link" href="#collection">Collection</a>
                  </li>
                  <li (click)="onCloseMenu()" class="nav-item">
                    <a class="nav-link" href="#brand">Brand</a>
                  </li>
                  <li (click)="onCloseMenu()" class="nav-item">
                    <a class="nav-link" href="#events">Events</a>
                  </li>
                  <li (click)="onCloseMenu()" class="nav-item">
                    <a class="nav-link" href="#articles">Articles</a>
                  </li>
                </ul>
              </div>
            </div>
            <div *ngIf="isBasicMenuVisible" class="tab-pane fade" id="pills-push-categories">
              <div class="push-navbar">
                <ul class="navbar-nav">
                  <li class="nav-item">
                    <a class="nav-link" href="{{ corpUrl }}/home">Home</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" href="{{ corpUrl }}/tenders">Tenders</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" href="{{ corpUrl }}/projects">Projects</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" href="{{ corpUrl }}/events">Events</a>
                  </li>
                  <li>
                    <div class="careers">Careers</div>
                    <ul class="submenu">
                      <li>
                        <a class="recruitment-line" href="{{ corpUrl }}/recruitments">
                          Recruitments
                        </a>
                      </li>
                      <li>
                        <a class="program-line" href="{{ corpUrl }}/programs"> Programs </a>
                      </li>
                    </ul>
                  </li>
                  <li class="nav-item">
                    <a
                      style="border-top: 1px solid #ddd !important"
                      class="nav-link"
                      href="{{ corpUrl }}/press-releases"
                      >Press Releases
                    </a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" href="{{ corpUrl }}/eaps">EAPs</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" href="{{ corpUrl }}/about-us">About Us </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="div-height">
    <router-outlet> </router-outlet>
  </div>

  <footer fxLayout="column" fxLayoutGap="2px">
    <div
      fxLayout="row"
      fxLayout.xs="row wrap"
      fxLayout.sm="row wrap"
      fxLayoutAlign="start stretch"
      fxLayoutGap="2px"
      fxLayoutGap.sm="0px"
      fxLayoutGap.lt-sm="0px"
      class="logo-height">
      <div
        class="footer-card-v2"
        [style.background-color]="primaryColor"
        fxLayout="column"
        fxFlex="35"
        fxFlex.sm="100"
        fxFlex.xs="100">
        <img
          *ngIf="data.footerLogoLink"
          class="footer-logo"
          [src]="data.footerLogoLink"
          alt="footer-logo" />
        <b>WAREHOUSE ADDRESS</b>
        <ul class="footer-row">
          <li style="display: flex; column-gap: 0.25rem">
            <i class="ri-building-3-line"></i>
            {{ data.address }}
          </li>
        </ul>
      </div>
      <div
        class="footer-card-v2"
        [style.background-color]="primaryColor"
        fxFlex="30"
        fxFlex.sm="100"
        fxFlex.xs="100">
        <b style="text-transform: uppercase" class="footer-heading">{{ data.name }} - THE STORE</b>
        <ul class="footer-row">
          <li style="display: flex; column-gap: 0.25rem">
            <i class="ri-home-2-line"></i>
            {{ data.storeAddress }}
          </li>
          <li style="display: flex; column-gap: 0.25rem">
            <!-- <a class="a-href" href="tel: 91 9999679875">+91 9999679875 </a> -->
            <i class="ri-mail-line"></i>
            <a class="a-href" href="mailto: {{ data.storeEmail }}">
              {{ data.storeEmail }}
            </a>
          </li>
          <li>
            <i class="ri-map-pin-2-line"></i>
            <div
              style="cursor: pointer"
              class="a-href"
              (click)="onMapInfo(data.long_text, data.lat_text)"
              target="_blank">
              Location
            </div>
          </li>
        </ul>
      </div>
      <div
        class="footer-card-v2"
        [style.background-color]="primaryColor"
        fxFlex="35"
        fxFlex.sm="100"
        fxFlex.xs="100">
        <b>CUSTOMER CARE</b>
        <ul class="footer-row">
          <li>
            <!-- <b>Got Questions ? Call us 24/7</b> -->
          </li>
          <li *ngIf="data?.contactNumber" style="display: flex; column-gap: 0.25rem">
            <i class="ri-phone-line"></i>
            <a class="a-href" href="tel: +{{ data.contactNumber }}"
              >Call Us: +{{ data.contactNumber }}</a
            >
          </li>
          <li *ngIf="data?.contactNumber" style="display: flex; column-gap: 0.25rem">
            <i class="ri-whatsapp-line"></i>
            <a class="a-href" href="https://wa.me/{{ data.contactNumber }}">Message on WhatsApp</a>
          </li>
          <li *ngIf="data?.email" style="display: flex; column-gap: 0.25rem">
            <i class="ri-mail-line"></i>
            <a class="a-href" href="mailto: {{ data.email }}">
              {{ data.email }}
            </a>
          </li>
          <li style="display: flex; column-gap: 0.25rem">
            <i class="ri-questionnaire-line"></i>
            <a [routerLink]="['/return']" class="a-href">FAQ</a>
          </li>
          <li style="display: flex; column-gap: 0.25rem">
            <i class="ri-git-repository-private-fill"></i>
            <a routerLink="/privacy-policy" class="a-href">Privacy Policy</a>
          </li>
        </ul>
      </div>
    </div>
    <div
      fxLayout="row wrap"
      fxLayoutAlign="space-between center"
      class="footer-end-v2"
      [style.background-color]="secondaryColor">
      <div style="padding-top: 20px" class="current-year">
        © {{ currentYear }} {{ data.name }}. All right reserved.
      </div>

      <div fxLayoutGap="10px" style="margin-right: 40px; margin-top: -15px">
        <p class="powered-by-text">
          Powered by
          <a target="_blank" style="text-decoration: none" href="https://www.xlayer.in">
            xLayer Technologies&#174; <br />
          </a>
          Build: {{ buildVersion }}
        </p>
      </div>
    </div>
    <div
      fxHide.gt-sm
      fxLayout="column"
      fxLayoutAlign="space-between center"
      class="footer-end-v2-mobile"
      [style.background-color]="secondaryColor">
      <div fxLayoutAlign="center center" style="padding-top: 20px; font-size: 12px">
        © {{ currentYear }} {{ data.name }}. All right reserved.
      </div>
      <a
        fxLayout="row"
        fxLayoutAlign="center center"
        target="_blank"
        style="text-decoration: none; font-size: 12px"
        href="https://www.xlayer.in">
        Powered by xLayer Technologies&#174; <br />
      </a>
      <div style="font-size: 12px">Build: {{ buildVersion }}</div>
    </div>
  </footer>
</div>
