<div class="main-div">
  <div class="wrapper">
    <owl-carousel-o [options]="customOptions">
      <ng-container *ngFor="let idx of sliderArray">
        <ng-template carouselSlide>
          <img loading="lazy" [src]="idx?.galleryDetails[0]?.linkLg" alt="home-slider" />
          <div class="cover">
            <div class="container">
              <div class="carousel-content">
                <div class="line"></div>
                <h1>{{ idx.title }}</h1>
                <h2>{{ idx.subTitle }}</h2>
                <a
                  [style.background-color]="idx?.actions[0]?.backgroundColor"
                  [style.color]="idx?.actions[0]?.labelColor"
                  class="shop-now-btn"
                  [href]="idx?.actions[0]?.url"
                  >{{ idx?.actions[0]?.label }}</a
                >
              </div>
            </div>
          </div>
        </ng-template>
      </ng-container>
    </owl-carousel-o>
    <div id="shop"></div>
  </div>

  <div class="full-row bg-light shop-div">
    <div class="container">
      <div class="row row-cols-lg-4 row-cols-md-2 row-cols-1 glass-models">
        <div class="col">
          <div
            style="cursor: pointer"
            class="banner-wrapper hover-img-zoom"
            (click)="navigateToShop('6385c2ab08be3ca799980f98')">
            <div class="banner-image overflow-hidden transation">
              <img loading="lazy" src="assets/images/71.webp" alt="food-image" />
            </div>
            <div class="banner-content position-absolute">
              <div class="product-tag"></div>
              <div class="product-title">
                <h4>
                  <a
                    class="text-dark mb-10 d-block text-uppercase"
                    href="/shop?catId=6385c2ab08be3ca799980f98"
                    >Food</a
                  >
                </h4>
                <a class="btn-link-down-line" href="/shop?catId=6385c2ab08be3ca799980f98"
                  >Shop Now</a
                >
              </div>
            </div>
          </div>
        </div>
        <div class="col">
          <div
            style="cursor: pointer"
            class="banner-wrapper hover-img-zoom"
            (click)="navigateToShop('6385c2c408be3ca799980f99')">
            <div class="banner-image overflow-hidden transation">
              <img loading="lazy" src="assets/images/72.webp" alt="texttile-image" />
            </div>
            <div class="banner-content position-absolute">
              <div class="product-tag"></div>
              <div class="product-title">
                <h4>
                  <a
                    class="text-dark mb-10 d-block text-uppercase"
                    href="/shop?catId=6385c2c408be3ca799980f99"
                    >Textile</a
                  >
                </h4>
                <a class="btn-link-down-line" href="/shop?catId=6385c2c408be3ca799980f99"
                  >Shop Now</a
                >
              </div>
            </div>
          </div>
        </div>
        <div class="col">
          <div
            style="cursor: pointer"
            class="banner-wrapper hover-img-zoom"
            (click)="navigateToShop('64b652afe3b3d39940f979e7')">
            <div class="banner-image overflow-hidden transation">
              <img loading="lazy" src="assets/images/73.webp" alt="craft-image" />
            </div>
            <div class="banner-content position-absolute">
              <!-- <div class="product-tag"><span>Coming Soon</span></div> -->
              <div class="product-title">
                <h4>
                  <a href="#" class="text-dark mb-10 d-block text-uppercase">Craft</a>
                </h4>
                <a
                  class="btn-link-down-line"
                  href="/shop?catId=64b652afe3b3d39940f979e7
                "
                  >Shop Now</a
                >
              </div>
            </div>
          </div>
        </div>
        <div class="col">
          <div
            style="cursor: pointer"
            class="banner-wrapper hover-img-zoom"
            (click)="navigateToShop('6139eda06ab9f69a90926985')">
            <div class="banner-image overflow-hidden transation">
              <img loading="lazy" src="assets/images/74.webp" alt="book-image" />
            </div>
            <div class="banner-content position-absolute">
              <!-- <div class="product-tag"><span>Coming Soon</span></div> -->
              <div class="product-title">
                <h4>
                  <a href="#" class="text-dark mb-10 d-block text-uppercase">Books</a>
                </h4>
                <a
                  class="btn-link-down-line"
                  href="/shop?catId=6139eda06ab9f69a90926985
                "
                  >Shop Now</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div style="width: 100%; margin: 0px">
    <table class="service-table-v1">
      <tr class="service-table-v1-row">
        <td class="service-table-v1-row-data">HOMEGROWN</td>
        <td class="service-table-v1-row-data">HANDPICKED</td>
        <td class="service-table-v1-row-data">HANDCRAFTED</td>
      </tr>
    </table>
  </div>

  <!-- Collection section start -->
  <div id="collection" class="collection"></div>
  <div class="full-row collection-div">
    <div class="container">
      <div class="row row-cols-lg-2 row-cols-1">
        <div class="col">
          <div
            style="cursor: pointer"
            class="banner-wrapper hover-img-zoom custom-class-121"
            (click)="clickToCollection()">
            <div class="banner-image overflow-hidden transation">
              <img
                loading="lazy"
                src="assets/images/75.webp"
                alt="handloom-and-handicraft-collection" />
            </div>
            <div class="banner-content">
              <div
                class="product-tag"
                style="
                  font-size: 15px;
                  text-transform: uppercase;
                  color: var(--theme-secondary-color);
                  letter-spacing: 3px;
                ">
                <!-- <span>Nela Handloom By James Eventis Dkhar</span> -->
              </div>
              <h2 style="margin: 10px 0 20px; font-size: 36px; font-weight: 600; cursor: pointer">
                <div (click)="clickToCollection()" class="text-dark mb-10 d-block">
                  HANDLOOM & HANDICRAFT COLLECTION
                </div>
              </h2>
              <a href="{{ hostUrl }}/store/pages/collections" class="btn-link-left-line btn-hover"
                >Know More</a
              >
            </div>
          </div>
        </div>
        <div class="col">
          <div
            style="cursor: pointer"
            class="banner-wrapper hover-img-zoom custom-class-121 image-right"
            (click)="clickToFoodCollection()">
            <div class="banner-image overflow-hidden transation">
              <img loading="lazy" src="assets/images/76.webp" alt="food-collection" />
            </div>
            <div class="banner-content">
              <div
                class="product-tag"
                style="
                  font-size: 15px;
                  text-transform: uppercase;
                  color: var(--theme-secondary-color);
                  letter-spacing: 3px;
                ">
                <span>Local Name:<br />U Chyrmit Lakadong</span>
              </div>
              <h2 style="margin: 10px 0 20px; font-size: 36px; font-weight: 600; cursor: pointer">
                <div (click)="clickToFoodCollection()" class="text-dark mb-10 d-block">
                  FOOD COLLECTION
                </div>
              </h2>
              <a
                href="{{ hostUrl }}/store/pages/foods-premium"
                class="btn-link-right-line btn-hover"
                >Know More</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Collection section End -->

  <!-- Brand section start -->
  <div id="brand" class="brand"></div>
  <div class="full-row bg-light brand-div">
    <div class="container">
      <div class="row offer-product">
        <div class="col-xl-5 col-lg-7">
          <h1 class="down-line-secondary text-uppercase mb-30 brand-title">
            Meghalayan Age - the store
          </h1>
          <div class="product type-product">
            <div class="product-wrapper">
              <div class="product-info">
                <h2 class="product-title">
                  <div>A unique blend of culture, tradition, and style.</div>
                </h2>
                <div class="brand-description">
                  <p style="text-align: justify">
                    To encourage the promotion of art, culture, and lifestyle of the people of the
                    hills while dedicated to combating the challenges of small artisans and emerging
                    entrepreneurs, the Government of Meghalaya devised a concept of the Meghalayan
                    Age, the store. The government's intention behind the launch of a physical store
                    in New Delhi, the capital city, and consequently in other online platforms and
                    the rest of the country is to have a one-stop market space for buyers, curators,
                    and art enthusiasts, and to convert years and months of arduous labour that have
                    been spent in looking for platforms to retain primitive traditions and cultures
                    by artists alike by the important tribes in Meghalaya.
                  </p>
                </div>
                <a href="{{ hostUrl }}/store/story" class="btn btn-dark text-uppercase rounded-0">
                  Know More
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-6 col-lg-5 offset-xl-1">
          <div class="xs-mt-30">
            <img loading="lazy" src="assets/images/77.webp" alt="brand-image" />
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Brand section End -->

  <!-- Event section start -->
  <div id="events" style="height: 140px"></div>
  <div class="event-div">
    <div class="container">
      <div class="row justify-content-center">
        <div>
          <h2 class="main-title">Events</h2>
          <span class="sub-title"
            >We host events every month which are handpicked experiences to showcase the best that
            Meghalaya has to offer. These events range from art exhibitions, film screenings,
            musical performances, product launches and much more, providing a platform for the local
            creative community to display their talent to a much wider audience.</span
          >
        </div>
      </div>
      <div class="row row-cols-xl-3 row-cols-md-2 row-cols-1 g-4 gy-xl-0">
        <div *ngFor="let idx of data" class="col">
          <div
            style="cursor: pointer"
            class="thumb-blog-simple transation hover-img-zoom"
            (click)="navigateToEventDetails(idx?.knowMoreLink)">
            <div class="post-image overflow-hidden">
              <div class="skeleton-loader">
                <div class="skeleton-animation">
                  <img
                    loading="lazy"
                    class="e-a-image-size"
                    [src]="idx?.imglink"
                    alt="event-images" />
                </div>
              </div>
            </div>
            <div class="post-content">
              <h3>
                <div class="content-title">{{ idx?.eventName }}</div>
              </h3>
              <p class="event-description">
                {{ idx?.description | slice : 0 : 150 }}
                {{ idx?.description.length > 150 ? '...' : '' }}
              </p>
              <a [href]="idx?.knowMoreLink" class="btn-link-down-line">Know More</a>
            </div>
          </div>
        </div>
      </div>
      <div style="display: flex; justify-content: center; margin-top: 20px">
        <div
          style="
            border-top: 1px solid #a9a6a6;
            flex-grow: 1;
            margin-right: 25px;
            margin-top: 20px;
          "></div>
        <a
          style="background: #2a3545"
          href="{{ hostUrl }}/store/event-list"
          class="btn-dark btn text-uppercase rounded-0">
          Show More
        </a>
        <div
          style="
            border-top: 1px solid #a9a6a6;
            flex-grow: 1;
            margin-left: 25px;
            margin-top: 20px;
          "></div>
      </div>
    </div>
  </div>
  <!-- Event section End -->

  <!-- Articles section start-->
  <div id="articles" style="height: 140px"></div>
  <div class="article-div">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-5">
          <h2 class="main-title">Articles</h2>
        </div>
      </div>
      <div
        class="row row-cols-xl-3 row-cols-md-2 row-cols-1 g-4 gy-xl-0"
        style="margin-bottom: 40px">
        <div *ngFor="let idx of value" class="col">
          <div
            style="cursor: pointer"
            class="thumb-blog-simple transation hover-img-zoom"
            (click)="navigateToArticlesDetails(idx?.articleLink)">
            <div class="post-image overflow-hidden">
              <div class="skeleton-loader">
                <div class="skeleton-animation">
                  <img
                    loading="lazy"
                    class="e-a-image-size"
                    [src]="idx?.image_text"
                    alt="article-images" />
                </div>
              </div>
            </div>
            <div class="post-content">
              <h3>
                <div class="content-title">
                  {{ idx?.title }}
                </div>
              </h3>
              <a [href]="idx?.articleLink" target="_blank" class="btn-link-down-line">
                Know More
              </a>
            </div>
          </div>
        </div>
        <!-- Articles section End-->
      </div>
    </div>
  </div>
</div>
