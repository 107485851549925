export const ProfileHelpers = {
  profileDetails: {
    currency: '₹',
    entityId: '',
    recentlyViewed: [],
    recentlyPurchased: [],
    closed: false,
    locked: false,
    active: true,
    walletAmount: 0,
    walletHistory: [],
    hasReviewed: false,
    id: '',
    accountId: '',
    firstName: '',
    lastName: '',
    contact: [
      {
        verified: true,
        value: null,
      },
    ],
    profilePicture: '',
  },
};
