import { Router } from '@angular/router';

export class Util {
  constructor() { }

  static replaceURLParameter(currentUrl: string, paramName: string, newValue: string) {
    var url = currentUrl;

    var baseURL = url.split('?')[0];
    var params = url.split('?')[1];

    if (!params) {
      // No parameters present in the URL, so add the parameter directly
      var newURL = baseURL + '?' + paramName + '=' + newValue;
      return newURL;
    }

    var paramArray = params.split('&');
    var updatedParams = '';
    var paramExists = false;

    for (var i = 0; i < paramArray.length; i++) {
      var param = paramArray[i];

      if (param.startsWith(paramName + '=')) {
        if (newValue === null) {
          // If the parameter value is null, skip it to remove the parameter
          paramExists = true;
          continue;
        }

        // Replace the parameter with the new value
        param = paramName + '=' + newValue;
        paramExists = true;
      }

      updatedParams += param;

      if (i < paramArray.length - 1) {
        updatedParams += '&';
      }
    }

    if (!paramExists && newValue !== null) {
      // If the parameter doesn't exist and the new value is not null, add it to the end of the parameter string
      updatedParams += '&' + paramName + '=' + newValue;
    }

    // Combine the base URL and updated parameters to form the new URL
    var newURL = baseURL + '?' + updatedParams;
    return newURL;
  }

  static changeURLWithoutReloadWithRouter(currentRouter: Router, newURL) {
    var stateObject = {};
    var newTitle = '';
    // var currentURL = window.location.href;

    history.pushState(stateObject, newTitle, newURL);

    currentRouter.navigateByUrl(newURL, {
      skipLocationChange: false,
    });
  }

  public getQueryParams(url: string): Record<string, string | null> {
    const urlObj = new URL(url);
    const params = new URLSearchParams(urlObj.search);
    const queryParams: Record<string, string | null> = {};

    params.forEach((value, key) => {
      queryParams[key] = value;
    });

    return queryParams;
  }
}
