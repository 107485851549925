import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class HomeService {
  public static dialogResult = false;
  headers: any;
  xcomHeaders: any;

  constructor(private http: HttpClient) {
    this.headers = {
      'x-Origin-Key': environment.corpxOrigin.key,
    };
    this.xcomHeaders = {
      'x-Origin-Key': environment.xOrigin.key,
    };
    this.headers = { ...this.headers, ...environment.platformInfo };
  }

  getEvents(paginatorParam: any): Observable<any> {
    return this.http.get<any>(environment.corpUrl + '/corpapi/events/list?inStore=1', {
      headers: this.headers,
      params: paginatorParam,
    });
  }

  getNewArticles(paginatorParam: any): Observable<any> {
    return this.http.get<any>(environment.corpUrl + '/corpapi/news/list', {
      headers: this.headers,
      params: paginatorParam,
    });
  }

  getHomeSliderSection(paginatorParam: any): Observable<any> {
    return this.http.get<any>(environment.proxyApiUrl + '/web/api/elemnts/section', {
      headers: this.xcomHeaders,
      params: paginatorParam,
    });
  }
}
