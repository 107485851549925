import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatBadgeModule } from '@angular/material/badge';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatMenuModule } from '@angular/material/menu';
import { NavigationStart, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { StaticMemory } from './handler/static_memory';
import { LoadingModule } from './loading/loading.module';
import { ElementWidgetsModule } from './element-widgets/element-widgets.module';
import { ImgMagnifier } from 'ng-img-magnifier';
import { ProductComponent } from './pages/product/product.component';
import { MatChipsModule } from '@angular/material/chips';
import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';
import { ShareIconsModule } from 'ngx-sharebuttons/icons';
import { NgxImgZoomModule } from 'ngx-img-zoom';
import { DragScrollModule } from 'ngx-drag-scroll';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { CarouselModule } from 'ngx-owl-carousel-o';

@NgModule({
  declarations: [AppComponent, ProductComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    CarouselModule,
    FlexLayoutModule,
    HttpClientModule,
    FormsModule,
    MatChipsModule,
    MatMenuModule,
    MatSelectModule,
    MatInputModule,
    MatFormFieldModule,
    MatButtonModule,
    MatIconModule,
    MatBadgeModule,
    MatSnackBarModule,
    MatButtonToggleModule,
    MatProgressSpinnerModule,
    ElementWidgetsModule,
    LoadingModule,
    ShareButtonsModule.withConfig({
      debug: false,
    }),
    ShareIconsModule,
    ImgMagnifier,
    NgxImgZoomModule,
    DragScrollModule,
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(private router: Router) {
    if (environment.xOrigin.key === null) {
      sessionStorage.clear();
      StaticMemory.isAuthVerified = false;
    } else {
      StaticMemory.isAuthVerified = true;
    }
    this.router.events.forEach((event) => {
      if (event instanceof NavigationStart) {
        sessionStorage.setItem(StaticMemory.xRoute, event.url);
        // if (event.url === '' && StaticMemory.isAuthVerified) {
        //   this.router.navigate(['']);
        // }
      }
    });
  }

}
