import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from 'src/environments/environment';
import { AUTHGWCONSTANTS } from '../../authorization/authconstatnts';
@Injectable({
  providedIn: 'root',
})
export class ShopService {
  public static dialogResult = false;
  headers: any;

  constructor(private http: HttpClient) {
    const token = localStorage.getItem(AUTHGWCONSTANTS.bearerToken);
    const xOriginKey = localStorage.getItem(AUTHGWCONSTANTS.xOriginKey);
    const xApiKey = localStorage.getItem(AUTHGWCONSTANTS.xApiKey);
    if (token !== undefined && token !== null) {
      this.headers = {
        Authorization: 'Bearer ' + token,
        'x-Origin-Key': xOriginKey,
        'x-Api-Key': xApiKey,
      };
    } else {
      this.headers = environment.guestHeaders;
    }
    this.headers = { ...this.headers, ...environment.platformInfo };
  }

  getProducts(urlParams: any): Observable<any> {
    return this.http.get<any>(environment.proxyApiUrl + '/web/api/component/product/v4', {
      headers: this.headers,
      params: urlParams,
    });
  }

  getBrands(): Observable<any> {
    return this.http.get<any>(environment.proxyApiUrl + '/web/api/components/brand/list', {
      headers: this.headers,
    });
  }

  getCategories(): Observable<any> {
    return this.http.get<any>(environment.proxyApiUrl + '/web/api/resource/category', {
      headers: this.headers,
    });
  }

  postWishlist(body: any): Observable<any> {
    return this.http.post<any>(
      environment.proxyApiUrl + '/web/api/v4/component/user/wishlist',
      body,
      {
        headers: this.headers,
      },
    );
  }

  deleteWishlistItem(urlParam: any): Observable<any> {
    return this.http.delete<any>(environment.proxyApiUrl + '/web/api/v4/component/user/wishlist', {
      headers: this.headers,
      params: { id: urlParam.subProductId },
    });
  }

  getSingleProduct(id: string): Observable<any> {
    return this.http.get<any>(environment.proxyApiUrl + '/web/api/resource/product?id=' + id, {
      headers: this.headers,
    });
  }

  postProductInCart(body: any): Observable<any> {
    return this.http.post<any>(environment.proxyApiUrl + '/web/api/online/customer_cart_v5', body, {
      headers: this.headers,
    });
  }

  getParentCategories(): Observable<any> {
    return this.http.get<any>(`${environment.proxyApiUrl}/web/api/resource/parent/category`, {
      headers: this.headers,
    });
  }
}
